$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.update {
  display: flex;
  margin-bottom: 20px;
  gap: 16px;
}

.dropdown {
  margin-bottom: 20px;
  ul {
    right: auto;
  }
}

.row {
  border: 1px solid #E3E3E3;
  padding: 14px;
  border-radius: 8px;
  gap: 15px;
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  color: #4E525A;
}

.wrap {
  display: flex;
  gap: 50px;
}

.wrapRow {
  display: flex;
  flex-flow: column;
}

.gray {
  color: #4E525A;
}

.red {
  color: $red;
}

.green {
  color: $green;
}

.pointer {
  cursor: pointer;
}

.icon {
  fill: #17B67C;
  height: 10px;
  width: 10px;
  margin-left: 5px;
  transform: rotate(-90deg)
}

.transform {
  transform: rotate(90deg)
}

.tableWrapper {
  width: 100%;
  overflow-x: auto;

  .table {
    min-width: 1200px; // Регулируйте значение в зависимости от количества столбцов
    table-layout: auto;
    margin-top: 10px;
    margin-bottom: 10px;

    tr {
      border-bottom: 1px solid #D3DFF0;

      th, td {
        padding-right: 45px;
        color: #5B6880;
        text-align: center;

        &:last-child {
          padding-right: 0;
        }

        &:first-child {
          text-align: left;
        }
      }
    }
  }
}

.italic {
  font-style: italic;
}