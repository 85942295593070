$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.label {
  font-weight: $bold-weight;
  flex: 1;
  margin-bottom: 24px;
}

.logoImage {
  max-height: 110px;
  max-width: 400px;
  border-radius: 8px;
  object-fit: cover;
}

.container {
  display: flex;
  align-items: baseline;
  margin-bottom: 24px;
  gap: 32px;
}

.information {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.buttonLogoContainer {
  display: flex;
  gap: 32px;
}

.hintIcon {
  fill: #17b67c;
  margin-left: 8px;
  margin-bottom: 6px;
}

.buttonWith {
  max-width: 124px;
}

.buttonWithMedium {
  max-width: 200px;
}

.updateData {
  color: $grey-400;
  font-weight: 400;
  line-height: 140%;
}

.tableTitleCellMedium {
  width: 415px;
  font-weight: $bold-weight;
  color: $darkBlue;
  margin-bottom: 4px;
}
.wrapper {
  margin-top: 25px;
  margin-bottom: 40px;
}
.titleStyle {
  display: flex;
  margin-bottom: 9px;
}

.mobile {
  display: none;
}
.flexTitle {
  display: flex;
  word-wrap: break-word;
}

.radioStyle {
  display: flex !important;
  flex-direction: column;
  label {
    padding-bottom: 10px !important;
  }
}

.loader {
  position: fixed;
  bottom: 100px;
  left: 28vw;
}

.actionsWrapper {
  justify-content: center;
  gap: 8px;
}

.actions {
  display: flex;
  margin-top: 16px;
  gap: 16px;
}

@media (max-width: 1200px) {
  .uploading {
    margin-top: 0px;
    margin-bottom: 30px;
  }
}

@media (max-width: 1180px) {
  .changeProfile {
    margin-top: 32px;
  }
}

@media (max-width: 1000px) {
  .information {
    gap: 26px;
    margin-top: 26px;
  }

  .hideMobile {
    display: none;
  }
  .mobile {
    display: block;
  }

  .container {
    flex-direction: column-reverse;
    gap: 0;
  }

  .label {
    margin-bottom: 4px;
  }

  .logoImage {
    max-width: 100%;
  }
  .wrapper {
    margin-top: 16px;
  }
  .titleStyle {
    margin-top: 20px;
  }
  .wrapper {
    margin-top: 0;
    margin-bottom: 0;
  }
  .flexTitle {
    display: contents;
    word-wrap: break-word;
    h1 {
      width: 300px;
    }
  }
  .updateData {
    width: 300px;
  }
  .radioStyle {
    label {
      padding-bottom: 0 !important;
    }
  }
}
