$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.row {
  display: grid;
  grid-row-gap: 24px;
}

.chapter {
  display: grid;
  grid-row-gap: 32px;

  h5 {
    display: inline;
    font-size: 16px;
    font-family: Inter, serif;
    margin-bottom: 0;
    margin-right: 60px;
  }
}

.fields {
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: minmax(245px, 25vw) auto;
  align-items: flex-start;

  &_verticalCenter {
    align-items: center;
  }

  &_reverse {
    grid-template-columns: auto 300px;
  }

  &_threeFields {
    grid-template-columns: minmax(245px, 25vw) 1fr 1fr;
  }

  label {
    margin-right: 0;
  }

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 24px;
  }


}

.w3 {
  width: 75px;
}

.wrapper {
  background: $white;
  //height: 65vh;
  padding: 0 30px;
  margin: 20px 0;
  //overflow-y: auto;
}

.formLabel {
  &_noMargin{
    margin-right: 0;
  }
}

.title {
  font-size: 16px;
  font-family: Inter, serif;
  word-wrap: break-word;
  margin-right: 60px;
}


.selectPersonWrapper {
  display: grid;
  grid-column-gap: 24px;
  //grid-template-columns: minmax(245px, 25vw) 86px auto;
  grid-template-columns: 10fr 2fr 11fr;
  align-items: start;


  @media screen and (max-width: 1100px) {
    grid-template-columns: minmax(165px, 20%) 86px auto;
    grid-row-gap: 24px;
  }

  @media screen and (max-width: 978px) {
    grid-template-columns: auto 86px;
    grid-auto-flow: dense;

    > :last-child {
      grid-column: 1 / -1;
    }
  }

  &_verticalCenter {
    align-items: center;
  }

}

.senderSelectPersonWrapper {
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: 9fr 2fr 14fr;
  align-items: start;


  @media screen and (max-width: 1100px) {
    grid-template-columns: minmax(165px, 20%) 86px auto;
    grid-row-gap: 24px;
  }

  @media screen and (max-width: 978px) {
    grid-template-columns: auto 86px;
    grid-auto-flow: dense;

    > :last-child {
      grid-column: 1 / -1;
    }
  }

  &_verticalCenter {
    align-items: center;
  }

}

.actions {
  display: flex;
  justify-content: flex-end;
  grid-column-gap: 60px;
  margin-top: 15px;
}

.victimSection {
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: auto;
  align-items: start;

  &_padding {
    padding-left: 20px;
  }

  &_gender {
    display: grid;
    grid-template-columns: 11fr 2fr;
  }

  @media screen and (max-width: 978px) {
    grid-template-columns: auto;
    grid-row-gap: 24px;
  }
}

.victimNonResidentDocuments {
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
  //grid-template-columns: 11fr 10fr 3fr;
  align-items: flex-end;
}

.victimPosition {
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
  //grid-template-columns: 11fr 10fr 3fr;
  align-items: flex-end;
}

.selectButton {
  margin-top: 27px;
}

.victimGenders {
  display: grid !important;
  grid-column-gap: 24px;
  grid-template-columns: 1fr 1fr;
  align-items: flex-end;
}

.circle {
  width: 36px;
  height: 36px;
  background-color: green;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.dateTimeField {
  display: flex;
  flex-direction: column;
  position: relative;
  grid-row-gap: 5px;
  height: 100%;
  .label {
    margin-bottom: 8px;
  }
}

.applicantRow {
  display: flex;
  gap: 44px;

  label {
    margin-right: 8px;
    font-weight: bold;

  }
}

.ml {
  margin-left: 24px;
}