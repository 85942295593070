a:hover {
  color: $green !important;
}

.menu {
  text-align:center;
  margin-bottom: 0;
}

.link {
  font-family: "Inter Medium", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 110%;
  color: white;
}

.greenBtn {
  transition: 0.2s;

  a:hover,
  button:hover {
    transition: 0.2s;
    background: $darkBlue;
  }

  .iconLink {
    background: none;
  }

  &.no-bg {
    a,
    button {
      border: 2px solid $green;
      background: none;
    }

    &:hover {
      a,
      button {
        background: $darkBlue;
        border: 1px solid rgba(0, 0, 0, 0);
      }
    }
  }

  a,
  button {
    font-family: "Inter Medium", sans-serif;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    padding: 11px 22px;
    background: $green;
    color: #fff;
    text-decoration: none;
    border-radius: 2px;
    display: inline-block;
  }
}

label {
  font-weight: 500;
}

.logo {
  max-width: 145px;
  width: 100%;
}

.header {
  height: 750px;
  background: url(../../../assets/home/Slide-1.jpg) no-repeat center top / cover;

  .circle {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 25vw;
    height: 25vw;
    overflow: hidden;

    &::after {
      border-radius: 50%;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: -10vw;
      right: -5vw;
      z-index: 0;

      background-color: #0a2f23;
      opacity: 0.5;
      background-blend-mode: multiply;
    }

    @media screen and (max-width: 768px) {
      width: 90vw;
      height: 90vw;
      right: -45vw;
      bottom: 15vw;

      &::after {
        right: 0;
        bottom: 0;
      }
    }
  }

  &-absolute__block {
    box-sizing: border-box;
    position: relative;
    top: -120px;
    padding: 35px;
    max-width: 1008px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background: #ffffff;
    box-shadow: 0px 10px 34px rgba(210, 210, 210, 0.5);
    border-radius: 4px;

    p {
      font-weight: bold;
      font-size: 48px;
      line-height: 60px;
      color: $green;
    }

    span {
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      color: #283339;
    }
  }

  &-offer {
    padding-top: 15%;
    padding-left: 10%;

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: -16px;

      .greenBtn {
        margin-right: 16px;

        a {
          width: 100%;
          display: block;
        }
      }
    }

    h1 {
      font-weight: bold;
      font-size: 64px;
      color: #fff;
    }

    p {
      margin: 20px 0px;
      font-size: 24px;
      line-height: 30px;
      opacity: 0.8;
      color: #fff;
    }
  }

  &-wrapper {
    padding: 20px 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    ul {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      list-style: none;

      li {
        padding: 10px;
        display: flex;
        cursor: pointer;
        color: #f7f7fa;
      }
    }

    &--dark {
      ul li {
        color: #1d293f;
      }
    }

    .header-li {
      font-family: "Inter Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 110%;
      color: #000000;
      margin-top: 14px;
      &-active {
        font-size: 16px;
        line-height: 110%;
        color: #17b67c;
      }
    }
  }
}

.css-1wa3eu0-placeholder {
  font-weight: normal;
  transform: translateY(-50%) !important;
  margin-left: 1px;
}

.carousel .control-next.control-arrow:before {
  border: none;
  width: 100px;
  height: 100px;
  background: url(../../../assets/home/Arrowprev.svg) no-repeat center top / contain;
}

.carousel .control-prev.control-arrow:before {
  border: none;
  background: url(../../../assets/home/Arrownext.svg) no-repeat center top / contain;
  width: 100px;
  height: 100px;
}

.control-prev {
  text-align: left;
  width: 100%;
}

.control-next {
  text-align: right;
  max-width: 1200px;
  width: 100%;
}

.carousel.carousel-slider .control-arrow {
  max-height: 30px;
}

.arrow-prev {
  position: absolute;
  bottom: -525px;
  width: 70px;
  height: 18px;
  z-index: 99;
  cursor: pointer;
  background: url(../../../assets/home/Arrownext.svg) no-repeat center top / contain;
  @media (max-width: 900px) {
    bottom: -565px;
  }
}

.container-fluid {
  height: 0px;
  max-width: 1008px !important;
}

.carousel-status {
  display: none !important;
}

.arrow-next {
  position: absolute;
  top: -242px;
  cursor: pointer;
  width: 70px;
  right: 0;
  height: 18px;
  z-index: 99;
  background: url(../../../assets/home/Arrowprev.svg) no-repeat center top / contain;

  @media (max-width: 900px) {
    top: -202px;
  }
}

.carousel .control-dots .dot {
  border-radius: 2px;
  width: 53px;
  height: 5px;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: $green;
}

.carousel .slider-wrapper {
  overflow: unset;
}

.carousel .control-dots {
  bottom: 25% !important;

  @media (max-width: 900px) {
    bottom: 20% !important;
  }
}

.carousel.carousel-slider {
  overflow: unset;
}

//HOME
.home {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  p {
    max-width: 364px;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    color: $black;
    line-height: 32px;
  }
  span {
    font-family: 'Inter', sans-serif;
    max-width: 393px;
    font-size: 16px;
    line-height: 28px;
    color: $darkBlue;
    text-align: left;
  }
  &-block_1 {
    padding: calc(var(--skew-padding) * 0.9) 0 0;
    &-item {
      width: 346px;
      background: $white;
      border-radius: 24px;
      padding: 24px;
      margin: 27px 36px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 22.0074px 24.1177px rgba(0, 0, 0, 0.058643)) drop-shadow(0px 9.14073px 10.0172px rgba(0, 0, 0, 0.045));

      img {
        margin-bottom: 10px;
        width: 42px;
        height: 42px;
      }

      &-frame {
        p {
          font-family: 'Montserrat Alternates', sans-serif;
          font-size: 18px;
          line-height: 22px;
          text-align: left;
          color: $black;
          margin-bottom: 12px;
        }

        span {
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          text-align: left;
          line-height: 150%;
          color: #6B7280;
        }
      }
    }

    @media screen and (max-width: 1210px) {
      &-item {
        margin: 27px 6px;
      }
    }

  }
  &-block_2 {
    padding: calc(var(--skew-padding) * 0.45) 0 calc(var(--skew-padding) * 0.3) 0;
    width: 393px;
    &-item {
      display: none;
    }
    @media screen and (max-width: 1210px) {
      &-item {
        display: block;
        width: 346px;
        background: $white;
        border-radius: 24px;
        padding: 24px;
        margin: 27px 6px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 22.0074px 24.1177px rgba(0, 0, 0, 0.058643)) drop-shadow(0px 9.14073px 10.0172px rgba(0, 0, 0, 0.045));

        img {
          margin-bottom: 10px;
          width: 42px;
          height: 42px;
        }
      }
    }
  }
  &-block_3 {
    padding: calc(var(--skew-padding) * 0.01) 0 0;
    width: 393px;
  }
  &-block_column {
    display: none;
  }
  &-slider {
    display: none;
  }

  @media screen and (max-width: 1210px) {
    &-block_3 {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    &-block_column {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 0 auto;
    }
    &-slider {
      display: block;
      position: relative;
      width: 520px;
      &-block {
        display: block !important;
        margin: 4% auto 5%;
        background: #fff;
        width: 90% !important;
        overflow: hidden;
        position: relative;
        border-radius: 24px;
      }
    }
    &-block_1 {
      display: none;
      &-item {
        padding: 20px 20px 20px 20px;
        filter: none;
        width: auto;
      }
    }
    &-block_2 {
      display: none;
    }
  }

  @media screen and (max-width: 571px) {
    &-slider {
      width: 400px;
    }
  }
  @media screen and (max-width: 425px) {
    &-slider {
      width: 350px;
    }
  }
  @media screen and (max-width: 375px) {
    &-slider {
      width: 320px;
    }
  }
  @media screen and (max-width: 340px) {
    &-slider {
      width: 280px;
    }
  }
}

// EMPLOYER
.employer {
  position: relative;

  .green-block {
    position: relative;
    img {
      width: 472px;
      height: 112px;
      position: absolute;
      left: 0;
    }
  }

  @media screen and (min-width: 1680px) {
    .green-block {
      position: relative;
      top: 20px;
    }
  }

  .green-rectangle-block {
    position: relative;
    background: white;
    img {
      width: 472px;
      height: 79px;
      position: absolute;
      right: 0;
    }
  }

  .green-line {
    max-height: 685px;
    padding: calc(var(--skew-padding) * 0.9) 36px 0 0;
  }

  @media screen and (max-width: 760px) {
    .green-line {
      max-height: 685px;
      padding: calc(var(--skew-padding) * 1.1) 20px 0 0;
    }
  }

  @media screen and (max-width: 375px) {
    .green-line {
      max-height: 685px;
      padding: calc(var(--skew-padding) * 0.9) 5px 0 0;
    }
  }
}

.employer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F9FAFB;
  transform: skewY(-5deg);
  z-index: -1;
}

// FORMS

.forms {
  margin-top: 120px;
  margin-bottom: 123px;

  &-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .title {
    margin-bottom: 40px;
  }

  &-block {
    &__wrapper {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }
  }
}

// WORKER
.worker {
  position: relative;
  margin-bottom: 80px;

  .green-line {
    max-height: 685px;
    padding: calc(var(--skew-padding) * 0.9) 36px 0 0;
  }

  .green-rectangle-block {
    position: relative;

    img {
      width: 472px;
      height: 79px;
      position: absolute;
      left: 0;
    }
  }

  @media screen and (min-width: 1680px) {
    .worker {
      padding: calc(var(--skew-padding) * 0.3) 0 0;
    }

    .green-rectangle-block {
      top: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .green-line {
      max-height: 685px;
      padding: calc(var(--skew-padding) * 0.3) 20px 0 0;
    }
    .green-rectangle-block {
      bottom: 10px;
    }

  }

  @media screen and (max-width: 375px) {
    .green-line {
      max-height: 685px;
      padding: calc(var(--skew-padding) * 0.3) 5px 0 0;
    }
  }
}

.worker::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F9FAFB;
  transform: skewY(-5deg);
  z-index: -1;
}

// FORM SECT
.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.datePicker-container {
  &::placeholder {
    color: $whiteBlue;
    font-size: 12px;
  }

  input,
  input:focus {
    outline: none !important;
  }

  .css-2613qy-menu {
    overflow: hidden scroll;
  }

  .jss1,
  .jss1:hover {
    top: -8px !important;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 1px solid #d3dff0 !important;
  }
}

.css-1hwfws3 {
  padding-left: 13px !important;
}

.css-1pahdxg-control {
  border: 1px solid #d3dff0 !important;
}

.css-1wa3eu0-placeholder {
  color: $whiteBlue !important;
  font-size: 12px;
  top: 45%;
}

.css-1pahdxg-control:hover {
  background: #f9fbff;
  border-color: #d3dff0 !important;
  box-shadow: none !important;
  outline: none !important;
}

.form-section {
  padding: 5em 0;
  &.active {
    padding-bottom: 26em;
  }

  .form-right-card {
    display: none;
    background: #ffffff;
    text-align: left;

    &.active {
      margin-top: 10px;
      display: block;

      p {
        font-family: 'Inter', sans-serif;
        font-weight: bold;
        font-size: 16px;
        line-height: 130%;
        color: #1D293F;
        margin: 4px 0px;
      }

      .p2 {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 140%;
        color: #1D293F;
      }
      .pEnd {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 140%;
        color: #1D293F;
      }
    }

    .card {
      &-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 20px;

        &-left, &-right {
          padding-right: 20px;
        }
      }
    }

    .name {
      margin-top: 10px;
      display: flex;
      align-items: center;

      img {
        margin-right: 15px;
      }

      span {
        padding: 8px 0px;
      }
    }

    .contacts {
      padding-bottom: 24px;

      p {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;

        img {
          margin-right: 14px;
        }

        span {
          font-size: 16px;
          color: #5b6880;
          line-height: 140%;
        }
      }
    }

    p {
      font-weight: bold;
      font-size: 16px;
      line-height: 130%;
      color: #080b11;
    }

    span {
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
      color: #5b6880;
      margin: 4px 0px;
    }
  }

  .form-wrapper {
    &.active {
      color: #080b11;
    }
    .greenBtn {
      text-align: center;

      button {
        position: relative;
        width: 180px;
        height: 40px;
        padding: 0 !important;
      }
    }

    margin: 0 40px;
    padding: 24px;
    background: #ffffff;
    text-align: center;
    box-shadow: 4px 4px 20px 4px rgba(73, 98, 135, 0.1);
    border-radius: 4px;

    p {
      font-family: 'Montserrat Alternates', sans-serif;
      margin: 10px;
      font-weight: bold;
      font-size: 24px;
      line-height: 130%;
      color: $darkBlue;
    }

    .css-1wa3eu0-placeholder {
      font-family: 'Inter', sans-serif;
      font-size: 16px;
    }

    .css-g1d714-ValueContainer {
      padding-left: 16px;
    }

    .css-1hb7zxy-IndicatorsContainer,
    .css-g1d714-ValueContainer {
      background: #f9fbff !important;
    }

    .custom-select {
      width: 18em;
      height: 40px;
      margin: 10px;
      text-align: left;
    }

    .form-block {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      p {
        margin-left: 0;
        margin-right: 8%;
      }
    }
  }
}

// INFO
.info {
  .infoSlider-wrapper {
    position: relative;
    top: -6em;

    .infoSlider-block {
      display: block !important;
      margin: 4% auto 5%;
      background: #fff;
      box-shadow: 4px 4px 20px 4px rgba(73, 98, 135, 0.1);
      width: 280px !important;
      height: 250px;
      overflow: hidden;
      position: relative;
      border-radius: 8px;


      .infoSlider-item {
        padding: 24px 24px 24px 24px;

        p {
          font-weight: 500;
          font-size: 18px;
          color: $darkBlue;
          z-index: 1;
        }

        a {
          display: block;
          color: $whiteBlue;
          font-weight: bold;
          font-size: 18px;
          text-decoration: none;
          z-index: 99;
          position: absolute;
          bottom: 65px;
        }

        img {
          position: absolute;
          right: 0;
          bottom: 0;
          z-index: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 860px) {

  .slick-next {
    right: 10px !important;
  }

  .slick-prev {
    left: 5px !important;
  }

}

// FOOTER

.footer {
  padding: 67.5px 0px;
  background: $darkBlue;

  &-disable {
    display: none;
  }

  a {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    margin-top: 14px;
  }

  span {
    font-family: Montserrat Alternates, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 46px;
    color: #fff;
  }

  p {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    margin-top: 14px;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-support {
    display: flex;
    justify-content: space-between;

    .block-left {
      &_contact {
        width: 320px;
        max-height: 36px;
        margin-top: 13px;
      }
    }

    .block-right {
      margin-top: 12px;
      margin-left: 270px;

      .greenBtn {
        text-align: center;

        button {
          position: relative;
          width: 234px;
          height: 40px;
          border-radius: 2px;
          padding: 0 !important;
        }
      }
    }
  }

  &-menu {
    display: flex;
    flex-direction: row;
    margin-top: 60px;
    margin-bottom: 120px;

    &-mobile {
      display: none;
    }

    &-logo {
      display: flex;
      flex-direction: row;

      &-soc {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 199px;
        height: 136.5px;

        img {
          max-height: 40.81px;
          margin-bottom: 1.69px;
        }
        p {
          margin-bottom: 0;
        }
      }

      &-number {
        padding-top: 43.5px;
        width: 300px;

        span {
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 42px;
          line-height: 51px;
          color: $green;
        }

        p {
          margin-top: 0;
        }
      }
    }

    &-general {
      margin-left: auto;

      span {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 203.4%;
        letter-spacing: 0.17em;
      }
    }

    &-portal {
      margin-left: auto;

      span {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 203.4%;
        letter-spacing: 0.17em;
      }
    }

    &-frame_logo {
      margin-left: auto;

      span {
        font-family: 'Inter', sans-serif;
        text-transform: uppercase;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 203.4%;
        letter-spacing: 0.17em;
        color: $white;
      }


      &-wrapper {
        display: grid;
        margin: 23px 28px;
        grid-template-columns: 158px 158px;
        grid-column-gap: 23px;
        grid-row-gap: 27px;
      }

      &-app_button {
        display: flex;
        align-items: center;
        gap: 16px;
        max-width: 342px;

        span{
          font-family: 'Inter', sans-serif;
          font-style: normal !important;
          font-weight: 400 !important;
          font-size: 12px !important;
          line-height: 14px !important;
          letter-spacing: 0 !important;
          text-transform: initial !important;
        }

        img{
          border-radius: 8px;
        }

        @media (max-width: 425px) {
          display: none;
        }
      }
    }
  }

  &-line {
    width: 180%;
    margin-left: -40%;
    border-top: 1px solid #2FAE7B;
  }

  &-copyright {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &-lang {
      //width: 110px;
      cursor: pointer;

      p {
        padding-left: 20px;
      }
      p:hover {
        color: $green;
      }

      img:hover {
        color: $green;
      }

      img {
        padding-left: 8px;
        padding-bottom: 3px;
      }
    }
  }
}

.gamburger {
  display: none;
  cursor: pointer;

  .fixed {
    position: fixed;
    top: 32px;
  }

  &.active {
    transition: 0.2s ease-out;

    span:first-child {
      transition: 0.2s ease-out;
      transform: rotate(45deg);
    }

    span:nth-child(2) {
      transition: 0.2s ease-out;
      transform: rotate(-45deg);
      margin-top: -11px;
    }

    span:last-child {
      transform: rotate(45deg);
      margin-top: -11px;
    }
  }

  transition: 0.2s ease-out;

  span {
    width: 20px;
    height: 2px;
    background: #fff;
    display: block;
    margin: 9px 0px;
  }
}

.mobile {
  // display: none;
}

// SIDEBAR
.sidebar-len {
  display: flex;
  align-items: center;
  padding: 15px 25px;

  span {
    margin-right: 15px;
    display: block;
    color: #f7f7fa;
    cursor: pointer;

    &.active {
      color: $green;
    }
  }
}

.sidebar-home {
  padding-top: 36px;
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: $darkBlue !important;
  top: 0;
  left: 0;
  z-index: 999;

  &.active {
    display: block;
  }

  ul {
    margin-top: 40px;
    margin-right: 45px;
    list-style: none;

    li {
      color: #f7f7fa;
      font-weight: 500;
      font-size: 16px;
      padding: 15px;
    }
  }

  .sidebar-head {
    &__wrapper {
      margin: 15px 0px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      img {
        display: block;
        max-width: 144px;
        max-height: 42px;
      }
    }
  }
}

.greenBtn.mob {
  .user {
    display: none;
  }
}

.employer-wrapper {
  .mob {
    display: none !important;
  }
}

.footer {
  .mobile {
    display: none;
  }
}

.greenBtn.mobile {
  display: none;
}

.tablet {
  display: none;
}

.tablet-wrapper {
  img {
    display: none !important;
  }
}

.errorForm {
  display: none;
  color: #ef5656 !important;
  font-size: 16px !important;
  margin: 0 !important;
  background-color: $white;
  left: 0;

  &.active {
    display: block !important;
  }
}

.greenBtn.mob {
  a:last-child {
    display: none;
    background: none;
  }
}

.fz-custom button {
  font-size: 14px;
  margin-top: 0;
}

.showPassword {
  position: relative;
  max-width: 450px;

  p.showPassword-text {
    position: absolute;
    top: 10px;
    right: 16px;
    font-weight: 600;
    padding: 0px 10px 0px 0px;
    text-decoration-line: underline;
    font-size: 14px;
    color: #aab8d1;
    cursor: pointer;
  }
}

.showPassword-label {
  position: absolute;
  bottom: 12px;
  right: 16px;
  font-weight: 600;
  padding: 0px 10px 0px 0px;
  text-decoration-line: underline;
  font-size: 14px;
  color: #aab8d1;
  cursor: pointer;
}

.showPassword_2 {
  position: relative;
  max-width: 450px;

  p.showPassword-text_2 {
    position: absolute;
    top: 10px;
    right: 16px;
    font-weight: 600;
    padding: 0px 10px 0px 0px;
    text-decoration-line: underline;
    font-size: 14px;
    color: #aab8d1;
    cursor: pointer;
  }
}

.rightSlideLogo-flex::after {
  background: linear-gradient(168.29deg, #1d293f -7.63%, rgba(29, 41, 63, 0.5) 55.46%, rgba(29, 41, 63, 0) 97.91%);
  position: absolute;
  content: '';
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
}

.rightSlideLogo-flex {
  position: relative;
  width: 100%;
  height: 100%;
}

.logo-wrapper-reg img {
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translate(-50%, -50%);
}

.wrapperLogoRight {
  position: absolute;
  left: 50%;
  top: 10%;
  z-index: 99;
  transform: translate(-50%, -50%);
}

.radioLabel {
  font-weight: 400;
}

.employerPassword {
  margin-bottom: 8px;
  position: relative;
}

.oferText {
  font-size: 14px;
}

.employer-block__wrapper-item img {
  position: relative;
}

.button-send-code {
  margin-top: 10px;
}

.header-wrapper {
  &--dark {
    margin-top: 25px;
  }
}

// АДАПТИВ
@media screen and (max-width: 1440px) {
  .green-block {
    bottom: 20px;
  }
  .footer {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 1210px) {
  .header-wrapper {
    .greenBtn.mob a:last-child {
      display: block !important;
    }
  }
  .container {
    margin: 0 15px !important;
    width: auto !important;
  }
  .container-footer {
    margin: 0 15px !important;
    width: auto !important;
  }
  .gamburger {
    display: block;
  }
  .greenBtn.mob {
    a {
      display: none;
    }

    .iconLink {
      display: block !important;
    }

    .user {
      display: block;
    }
  }
  .header-wrapper {
    .menu {
      display: none;
    }

    img {
      max-width: 144px;
      max-height: 42px;
    }
  }
  .employer-wrapper {
    display: grid;
    justify-content: center;

    .employer-block {
      text-align: center;
      max-width: 100%;
    }

    .employer-block__wrapper-item {
      text-align: left;
    }

    .greenBtn {
      margin-bottom: 50px;
    }
  }
  .employer span {
    max-width: 100%;
  }
}

@media screen and (max-width: 1190px) {
  .tetl {
    display: none !important;
  }
  .pss {
    .resetpassword_leftSide__3h4fV {
      width: 100% !important;
    }

    .resetpassword_wrapper__1GaIa {
      padding: 10px;
    }

    .resetpassword_mainWindow__3l1UJ {
      height: 100% !important;
    }
  }
}

@media screen and (max-width: 1100px) {
  .header-offer h1 {
    font-size: 56px;
  }
  .form-right {
    margin-top: 30px;
  }
  .tablet-wrapper {
    display: flex;
    justify-content: space-between;

    img {
      width: 100%;
      display: block;
      max-width: 261px;
    }
  }
  .worker-block span {
    text-align: left;
  }
  .employer {
    .greenBtn {
      display: none;

      .employer-block {
        margin-top: 35px;
      }

      &.tablet {
        margin-top: 35px;
        display: block;
      }
    }
  }
  .title {
    font-size: 40px !important;
  }
  .worker-wrapper .mobile {
    text-align: left;
  }
  .worker-block p {
    text-align: left;
    font-size: 20px;
  }
  .employer .greenBtn.tablet {
    display: none;
  }
  .employer .greenBtn.mobile {
    display: block;
    text-align: left;
    margin: 0;
    margin-top: 35px;
  }
  .employer-wrapper .mob {
    display: block !important;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    margin-top: 50px;
  }
  .worker-wrapper .tablet {
    display: none !important;
  }
  .tablet-wrapper img {
    display: none !important;
  }
  .worker-wrapper .greenBtn.mobile {
    display: block !important;
  }
  .employer p {
    font-size: 20px;
    text-align: left;
    margin-bottom: 20px;
  }
  .employer-block__wrapper {
    margin-top: 35px;
  }
  .employer-block__wrapper-item {
    padding-bottom: 0px;
  }
  .header-absolute__block p {
    font-size: 32px;
  }
  .employer-wrapper.mobile {
    text-align: left;
  }
  .header-absolute__block span {
    font-size: 18px;
  }
  .tablet {
    display: block;

    img {
      display: block;
      width: 100%;
      max-width: 300px;
      object-fit: contain;
    }
  }
  .form-wrapper {
    display: grid !important;
    justify-content: center !important;
  }
  .form-right__wrapper {
    max-width: 100%;
  }
  .form-left {
    max-width: 100%;
    margin-bottom: 25px;
  }
  .worker-wrapper {
    display: grid;
    justify-content: center;
    text-align: center;

    .greenBtn {
      display: none;
    }

    .tablet {
      text-align: left !important;
      display: block;
    }

    .worker-block {
      max-width: 100%;
    }

    .worker-block:first-child {
      img {
        display: none;
      }
    }

    .employer-block__wrapper-item {
      text-align: left;
    }
  }
  .title {
    text-align: left;
  }
  .footer {
    &-support {
      .block-right {
        margin-left: 170px;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .green-block {
    bottom: 40px;
  }
  .green-rectangle-block {
    bottom: 40px;
  }
}

@media screen and (max-width: 860px) {
  .footer-wrapper {
    grid-template-columns: 1fr;

    .footer-contact__num {
      margin-top: 40px;
    }

    .footer-menu {
      margin-top: 40px;
    }

    ul {
      li {
        p {
          display: none;
        }
      }
    }

    .mobile {
      display: block !important;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      opacity: 0.7;
      margin-top: 40px;
    }
  }
}

@media screen and (max-width: 768px) {
  .green-block {
    bottom: 10px;
    img {
      width: 272px !important;
      height: 62px !important;
    }
  }
  .green-rectangle-block {
    bottom: 60px;
    img {
      width: 272px !important;
      height: 62px !important;
    }
  }
  .title {
    text-align: left;
  }
  .header-offer h1 {
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    padding-top: 30%;
  }
  .header-offer__btn {
    display: block;

    .greenBtn {
      margin: 16px 0px;
      max-width: 224px;
    }
  }
  .header-absolute__block {
    display: block;

    .header-absolute__block-item {
      margin-bottom: 40px;
    }
  }
  .header-offer p {
    text-align: left;
    font-size: 16px;
  }
  .form-block {
    display: flex;
    flex-direction: column !important;
    padding-bottom: 20px;
  }
  .form-section {
    padding-top: 0;
    padding-bottom: 200px;
    &.active {
      padding-bottom: 450px;
    }
  }
  .footer {
    padding: 20px 0;
    &-disable {
      display: flex;
      margin-bottom: 10px;

      span {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 203.4%;
        letter-spacing: 0.17em;
        color: $white;
      }
    }
    &-menu {
      justify-content: space-around;
      &-frame_logo {
        display: none;
      }
    }
    &-support {
      .block-right {
        margin-left: 10px;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .tablet img {
    margin: 0 auto;
  }
}

@media screen and (max-width: 550px) {
  .header-offer h1 {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    padding-top: 50%;
  }
  .green-block {
    bottom: 50px;
    img {
      width: 272px !important;
      height: 62px !important;
    }
  }
  .green-rectangle-block {
    bottom: 60px;
  }
  .employer {
    padding: 40px 0px;
  }
  .worker {
    padding: 40px 0px;
  }
  .form-left {
    display: grid;
    justify-content: center;
  }
  .form-section .form-left {
    display: block;
  }
  .form-section .form-right__wrapper {
    margin: 0 auto;
    max-width: 95%;
  }
  .form-section .form-right-card {
    box-shadow: unset;
  }
  .header-absolute__block {
    padding: 20px;
  }
  .carousel.control-dots.dot {
    border-radius: 2px !important;
    width: 23px !important;
    height: 3px !important;
  }
  .title {
    font-size: 32px !important;
    line-height: 38px !important;
  }
  .carousel .control-dots .dot {
    width: 30px !important;
    height: 3px !important;
  }
  .form-section {
    padding-top: 0;
    font-size: 20px;
    .form-wrapper {
      margin: 0 10px;
      p {
        margin: 0 10px;
        font-size: 21px;
        line-height: 26px;
        text-align: center;
      }
    }
  }
  .footer {
    &-disable {
      margin-top: 26px;
      margin-left: 38.36px;
      margin-right: auto;
      flex-direction: column;

      .footer-menu-frame_logo-app_button{
        display: flex !important;
        flex-direction: column;
        align-items: start;
        width: 240px;

        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    &-support {
      display: flex;
      flex-direction: column;
      .block {
        &-left {
          margin-left: auto;
          margin-right: auto;

          span {
            display: block;
            font-size: 21px;
            line-height: 26px;
            text-align: center;
          }

          p {
            text-align: center;
          }

          &_contact {
            max-width: 320px;
            max-height: 36px;
            margin-top: 13px;
          }
        }
        &-right {
          padding-top: 31px;
        }
      }
    }
    &-menu {
      display: none;
      &-general {
        width: auto;
        margin-top: 70px;
        margin-left: initial;
      }
      &-mobile {
        display: flex;
        flex-direction: row;
        &-block {
          width: auto;
          height: 110px;
          margin-bottom: 50px;
        }
        &-left {
          display: flex;
          width: 50%;
          flex-direction: column;
          padding-top: 32.85px;
          margin-left: 38.36px;
          img {
            max-height: 40.81px;
            margin-bottom: 1.69px;
          }
        }
        &-right {
          display: flex;
          flex-direction: column;
          width: 50%;
          padding-top: 32.85px;
          margin-left: 38.36px;
          span {
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 32px;
            line-height: 42px;
            color: $green;
          }

          .footer-menu-portal {
            width: auto;
            margin-top: 70px;
            margin-left: 0;
            span {
              font-family: 'Inter', sans-serif;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 203.4%;
              letter-spacing: 0.17em;
              color: $white;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .green-block {
    bottom: 60px;
    img {
      width: 272px !important;
      height: 62px !important;
    }
  }
  .green-rectangle-block {
    top: 10px !important;
  }
  .form-section {
    box-sizing: border-box;
    margin-top: 12px;
    display: block;

    .Button {
      margin-top: 24px;
    }
  }
  .header .header-absolute__block {
    margin-top: 53px;
  }
  .footer {
    &-disable {
      width: 288px;
    }
    &-menu-frame_logo-wrapper {
      grid-template-columns: 114px 114px;
      grid-column-gap: 10px;
      grid-row-gap: 27px;
      img {
        width: 116px;
        height: 44px;
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .form-section {
    padding-top: 0;
    padding-bottom: 200px;
    &.active {
      padding-bottom: 550px;
    }
  }
  .form-section .form-right-card.active {
    p {
      text-align: left;
    }
    .pEnd {
      padding-top: 30px;
    }
  }
  .form-section .form-right-card .card-wrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 425px) {
  h1.typography {
    font-size: 32px;
  }
}

@media screen and (max-width: 320px) {
  .form-section {
    padding-top: 0;
    padding-bottom: 200px;
    &.active {
      padding-bottom: 600px;
    }
  }
  .container-footer {
    margin: 0 auto !important;
    width: auto !important;
  }
}

@media screen and (max-width: 345px) {
  .info .infoSlider-wrapper .infoSlider-block {
    width: 250px !important;
  }
}

.width-pass {
  position: relative;

  img {
    position: absolute;
    width: 30px;
    top: 26px;
    right: 8px;
    z-index: 99;
    cursor: pointer;
  }
}

// Result

.tr-result {
  width: 75%;
}
