$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.actions {
  display: flex;
  grid-column-gap: 32px;
  justify-content: flex-end;
}
.wrapper {
  display: grid;
  grid-row-gap: 20px;
}

.form {
    display: grid;
    grid-row-gap: 28px;

}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 18px 32px;
  justify-content: space-between;
  align-items: flex-start;

  .column {
    display: grid;
    grid-row-gap: 18px;
  }

  .fields {
    display: grid;
    grid-row-gap: 18px;
  }
}

.firstRow {
  display: flex;
  gap: 24px;
}

.field {
  display: grid;
  grid-column-gap: 24px;
}

.address {
  grid-template-columns: 3fr 2fr;
  gap: 14px;
}

.house {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px;
}

.website {
  grid-template-columns: 1.5fr 1fr;
  gap: 14px;
}

.phones {
  grid-template-columns: 2fr 1fr;
}

.location {
  grid-template-columns: 1fr 1fr;
  gap: 14px;
}

.textarea {
  height: 137px;
  grid-column-start: 1;
  grid-column-end: 3;
}

.accountDetails {
  display: grid;
  grid-row-gap: 16px;

  button {
    width: max-content;
  }
}

.refreshIcon {
  fill: #ffffff;
}

.ecpButton {
  gap: 12px;
}

.formLabel {
  font-weight: $bold-weight;

  &_noMargin{
    margin-right: 0;
  }
}

@media (max-width: 1000px) {
  .grid {
    grid-template-columns: 1fr;
    gap: 14px;
  }

  .firstRow {
    flex-direction: column-reverse;
  }

  .address, 
  .website,
  .location,
  .phones {
    grid-template-columns: 1fr;
  }

  .textarea {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
  }

  .fields {
    grid-row-gap: 14px;
  }

}
