$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.modal {
  padding: 32px;
  max-width: 1216px;
  width: 90%;
}

.wrapper {
  height: calc(100% - 76px);
  overflow-y: scroll;
  padding-right: 24px;
  padding-left: 16px;
  max-height: 80vh;

  &__button {
    display: flex;
    position: relative;
    right: 0 !important;
    margin-top: 1em;
  }

  &__buttonRight {
    width: max-content;
    cursor: pointer;
    margin-right: 32px;
  }
}

.labelStatus {
  margin-top: 20px;
  color: var(--Secondary, $darkBlue);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  label {
    color: var(--Secondary-Text, $grey-100);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
}

.mainInformation {
  display: flex;
  justify-content: space-between;
}

.detailInformation {
  display: flex;
  // grid-column-gap: 32px;
}

.identifier {
  color: $grey-400;
  margin: 8px 0;
}

.registrationDate {
  color: $grey-100;
  margin-bottom: 34px;
}

.actions {
  display: flex;
  gap: 16px;
}

.button {
  padding-top: 6px;
  padding-bottom: 6px;

  &Table {
    @extend .button;
    margin-top: 32px;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.informationSections {
  display: flex;
  flex-direction: column;
  gap: 56px;
  margin-top: 64px;
}

.button__text__white {
  color: $white
}

.icon {
  width: 14px;
  height: 14px;
  margin-right: 4px;

  &Green {
    @extend .icon;
    fill: $green;
  }

  &Grey {
    @extend .icon;
    fill: $grey-100
  }

  &Red {
    @extend .icon;
    fill: $red;
  }

  &White {
    @extend .icon;
    fill: $white;
  }
}

.sub__title__action {
  color: $green;
  cursor: pointer;
}

.error {
  color: #E65332;
}

// etd

.editor__wrapper {
  border: 1px solid #AAB8D1;
  border-radius: 14px;
  padding: 12px;
  cursor: default;
  color: #1D293F;
  margin-top: 15px;
}

.editor__subwrapper {
  // padding: 12px;
  max-height: 729px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #5b6880;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.dialog {
  min-width: 500px;
  max-width: 500px !important;
  padding-right: 0;
  padding-left: 0;
}

.sign {
  min-width: 500px;
  max-width: 500px !important;
  padding-right: 0;
  padding-left: 0;
}

.buttons__wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 32px 0 30px;
}

.dialog__buttons__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reset__button__wrapper {
  margin-bottom: 20px;
}

.margin_button {
  margin: 20px;
}

.flex_button {
  display: flex;
}

.reset__button {
  font-size: 14px !important;
  color: #9aa0ac !important;
}

.button_etd {
  font-family: 'Inter', sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 130% !important;
  color: #5B6880 !important;
  padding: 0 !important;
  text-transform: none !important;
}

.button__save {
  margin-right: 30px !important;
  margin-left: 30px !important;
  padding: 5px 15px !important;
  border: 1px solid #17B67C !important;
  font-family: 'Inter', sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 130% !important;
  color: #17B67C !important;
}

.button__disabled {
  opacity: 0.5 !important;
}

.back__button {
  color: #17B67C !important;
}

.header {
  margin-top: 15px;
  margin-bottom: 22px;
}

.sub__title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  color: #5B6880;
  margin-bottom: 24px;
}

.dialog__template {
  max-width: 1028px !important;
}

.etd_mini_plug {
  margin-right: 32px;
  background: #5B6880;
  color: #ffffff;
  padding: 2px 12px;
  font-weight: 400;
  font-size: 18px;
}

.status__wrapper {
  color: #AAB8D1;
  // padding-bottom: 60px;
  span {
    font-size: 18px;
  }
}
.table__status__wrapper {
  color: #AAB8D1;
  span {
    font-size: 12px;
  }
}

.status__icon__wrapper {
  margin-right: 8px;
}
.table__status__icon {
  width: 16px;
  height: 16px;
  svg {
    width: 16px;
    height: 16px;
  }
}

.etd__title {
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  color: #5B6880;
}

.id__wrapper {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #9CA5B6;
}
.date__wrapper {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #5B6880;
}

.edit__icon {
  width: 22px;
  height: 22px;
}

.status__title {
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  color: #5B6880;
  margin-bottom: 15px;
}

.table__head__row {
	background-color: #5B6880;
	color: white !important;
	span {
		color: white !important;
	}
	svg {
		color: white !important;
	}
}

.table__row:nth-child(2n) {
	background-color: #EFF3FC;
}

.table__container {
	border-radius: 8px 8px 0px 0px;
	border: 1px solid $light-grey-200;
  overflow: hidden;
  overflow-x: unset !important;
}

.decline__button {
  margin-right: 30px;
}

.red__status {
  color: #EB5757;
}

.content {
	width: 400px;
	text-align: center;
	img {
		margin-bottom: 12px;
	}
}

.sign__title {
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 130% !important;
  text-align: center !important;
  color: #1D293F !important;
  padding-top: 65px !important;
}

.tabs__root {
  margin-top: 21px;
}

.tabs__flexContainer {
  justify-content: center !important;
}

.tab__indicator {
  background-color: #1D293F !important;
}

.tab__root {
  color: #1D293F !important;
}

.textarea__wrapper {
  width: 100%;
  margin-bottom: 32px;
}

.prolongation__info {
  &__title {
    font-weight: 700;
    font-size: 14px;
    line-height: 110%;
    color: #1D293F;
    margin-bottom: 4px !important;
  }
  &__value {
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    color: #5B6880;
  }
}

.prolonagation__wrapper {
  margin-bottom: 50px;
}

.state__red__color {
  color: #EB5757;
}

.employee__comment__wrapper {
  margin-top: 31px;
}

.employee__comment {
  border: 1px solid #AAB8D1;
  border-radius: 14px;
  margin-top: 15px;
  padding: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #1D293F;
}
.reset__title {
  width: 400px !important;
  margin: 30px auto 20px !important;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 130% !important;
  color: #1D293F !important;
  text-align: center !important;
}

.error__qr {
  text-align: center;
  color: #E65332;
  margin-bottom: 20px;
}

.review_wrapper{

}

.review__header{
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;


  h1{
    margin-bottom: 0;
  }
}

.review_list{
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.review_edit_button{
  color: $green;
  font-weight: 400;

  svg{
    fill: $green;
  }
}