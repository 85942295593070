$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

$main: #17b67c;
$active: #1d293f;
$secondary: #5b6880;

.Button {
	background-color: $main;
	border-radius: 2px;
	font-weight: 500;
	font-size: 16px;
	line-height: 110%;
	text-align: center;
	color: #fff;
	height: 40px;
	padding: 11px 16px 12px 16px;
	outline: none;
	border: none;
	cursor: pointer;
	transition: all 0.1s ease;
	display: inline-flex;
	align-items: center;
	text-transform: capitalize;
  $this: &;

	&,
	& * {
		box-sizing: border-box;
	}
	img {
		margin-right: 8px;
	}

	&:not(:disabled):hover,
	&:not(:disabled):active {
		background-color: $active;
	}
	&:focus {
		box-shadow: inset 0 0 0 1px $active;
	}

	&:disabled {
		opacity: 0.5;
	}

	&--bordered {
		background-color: transparent;
		box-shadow: inset 0 0 0 2px $main;

		&:not(:disabled):hover,
		&:not(:disabled):active {
			background-color: $active;
			box-shadow: none;
		}

		&:focus {
			box-shadow: inset 0 0 0 2px #fff;
		}
	}
	&--borderless {
		background-color: transparent;
		box-shadow: none;

		&:not(:disabled):hover,
		&:not(:disabled):active {
			background: #fff;
		}
	}
	&--green {
		color: $main;

		&:not(:disabled):hover,
		&:not(:disabled):active {
			color: #fff;
		}

    &#{$this}--borderless:hover {
      color: $active;
    }

	}
	&--secondary {
		color: $secondary;

		&.Button--bordered {
			box-shadow: inset 0 0 0 2px $secondary;
		}

		&:not(:disabled):hover,
		&:not(:disabled):active {
			background-color: #fff;
		}

		&:focus {
			box-shadow: inset 0 0 0 2px $secondary;
		}
	}
	&--gray {
		padding-left: 0;
		padding-right: 0;
		font-size: 16px;
		line-height: 110%;
		color: #aab8d1;

		&:focus {
			box-shadow: none;
		}
	}
}
