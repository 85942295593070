.accordion {
  margin-bottom: 1rem;
  box-shadow: none !important;

  &:before {
    height: 0 !important;
  }

  &__summary {
    background-color: #17B67C !important;
    color: white !important;
    font-weight: 500;
    font-size: 18px;
    flex-direction: row-reverse;
    border-radius: 8px !important;
    gap: 2px;
  }

  &__icon {
    fill: white !important;
  }

  &__body {
    color: #5B6880;
  }
}

.title {
  font-weight: 500;
  font-size: 18px;
}

.documentRow {
  display: flex;
  justify-content: space-between;
}

.tableTitleCell {
  width: 215px;
  font-weight: $bold-weight;
  color: $darkBlue;
  margin-bottom: 4px;
}

.dataRow {
  &:nth-child(odd) {
    background: #EFF3FC;
  }
}

.date__info {
  font-style: italic;
}

.table_header p{
  font-weight: 700;
  color: #1d293f;
  margin-bottom: 4px;
}

.row {
  background: #eff3fc;
  display: flex;
  gap: 15px;
  p {
    font-weight: 700;
    color: #1d293f;
    margin-bottom: 4px;
  }
  p:first-child {
    width: 215px;
  }
}