button.ant-btn > span {
    font-size: 16px!important;
    color: white;
}
button.ant-btn {
    background-color: #17B67C;
}
div#return_button {
    font-size: 16px!important;
    color: #17B67C;
}
div#return_button {
    font-size: 16px!important;
    background-color: #FFFFFF!important;
}
