$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.select {
	max-width: 240px;
	position: relative;
}

.active {
	position: relative;
	padding: 0 8px;
	background: $white-100;
	border: 1px solid $light-grey-200;
	box-sizing: border-box;
	border-radius: 4px;
	height: 28px;
	display: flex;
	align-items: center;
	grid-column-gap: 10px;
	justify-content: space-between;
	cursor: pointer;

	img {
		width: 10px;
	}
}

.menu {
	display: none;
	margin-top: 10px;
	list-style: none;
	background: $white;
	box-shadow: 4px 4px 20px 4px rgba(73, 98, 135, 0.1);
	border-radius: 4px;
	position: absolute;
	right: 0;
	z-index: 50;
	&.open {
		display: block;
	}

	li {
		display: flex;
		align-items: center;
		font-weight: normal;
		font-size: 14px;
		line-height: 120%;
		padding: 12px 16px;
		color: $whiteBlue;
		cursor: pointer;

		img {
			margin-right: 15px;
		}

		&:hover {
			background: $white-100;
		}
	}
}
