$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.fields__wrapper {
	display: flex;
	justify-content: space-between;
}

.field__input {
	width: 100%;
	margin-right: 16px;
}
.fieldAndButton {
	display: grid;
	grid-template-columns: max-content 1fr;
	grid-column-gap: 32px;
	align-items: flex-start;

	Button {
		margin-top: 26px;
	}
}

.fields__item__button {
	width: 40%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.fields__item__no__button {
	width: 100%;
	margin-left: 32px;
}

.workers__info {
	margin-top: 34px;

	&__title {
		font-weight: 400;
		font-size: 24px;
		line-height: 130%;
		color: $grey-100;
		margin-bottom: 11px;
	}
}

.table__head__row {
	background-color: #5B6880 !important;
	color: white !important;
	span {
		color: white !important;
	}
	svg {
		color: white !important;
	}
	padding: 10px !important;
}

.table__container {
	border-radius: 8px 8px 0px 0px;
	border: 1px solid $light-grey-200;
}

.checkbox {
	&__head {
		&__root {
			color: $white !important;
		}
	}

	&__checked {
		color: $green !important;
	}
}

.table__body_cell {
	padding: 12px !important;
}
.button {
	margin-left: 16px;
	margin-right: 16px;
	cursor: pointer;
}
.reset__button { color: $whiteBlue }
.reset__button__active { color: $green }

.empty__table__plug {
	font-size: 24px;
	line-height: 130%;
	color: $grey-100;
	text-align: center;
	border-top: none;
	padding: 80px 0;
}

.buttons__wrapper {
	margin-top: 45px;
}