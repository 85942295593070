$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.wrapper {
  display: inline-flex;
  flex-direction: column;

  &__buttonRight {
    width: max-content;
    cursor: pointer;
    margin-left: 32px;
    margin-bottom: 25px;
  }

  &__button {
    width: max-content;
    cursor: pointer;
    margin-bottom: 25px;
  }
}

.information {
  flex: 2;
  border-left: 3px solid #CDDEED;
  display: flex;
  align-items: center;
  gap: 16px;
}
.hintIcon {
  width: 30px;
  height: 30px;
  margin-left: 16px;
}
.text {
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
  line-height: 20px;
  margin: 25px 0;
}
.greenLink {
  color: #17B67C;
}
.content {
  width: 388px;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #1D293F;
  }
}
.title {
  color: #1D293F;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
}
.searchRow {
  background-color: #E7EEF8;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
  padding: 12px 30px 12px 16px;
  position: relative;
}

.searchField {
  background-color: white;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding-right: 10px;
  max-width: 700px;
  margin-right: 1rem;

  &__left {
    display: flex;
    flex-grow: 1;
  }

  &__button {
    color: #5B6880;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    cursor: pointer;
    margin-left: 10px;
  }
}

.advancedSearch {
  &__row {
    display: flex;
    flex-wrap: wrap;
    border-radius: 4px;
    background-color: #E7EEF8;
    padding: 0 16px 24px;
  }

  &__field {
    display: flex;
    flex-direction: column;
    width: 184px;
    margin-right: 16px;
    margin-top: 25px;
  }

  &__label {
    color: #1D293F;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
  }

  &__input {
    background-color: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #D3DFF0;
    padding: 9px 8px;
    padding-right: 35px;
    height: 32px;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #AAB8D1;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #AAB8D1;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #AAB8D1;
    }
  }

  &__input__icon {
    padding-left: 28px;
  }

  &__selectIcon {
    right: 20px;
    top: 17px;
  }

  &__datePickerInput {
    width: 140px;
  }

  &__datepickerIcon {
    height: 15px;
    width: 15px;
    margin-top: -10px;
  }

  &__button {
    color: #1D293F;
    font-weight: 700;
    cursor: pointer;
  }

  &__icon {
    margin-right: 8px;
  }

  &__searchButtonsRow {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 24px;
  }
}

.export__wrapper {
  margin-left: auto;
  cursor: pointer;
}

.export__text {
  margin-left: 8px;
}

.sign__buttons__wrapper {
  margin-top: 30px;
}

.sign__button {
  margin-right: 16px !important;
}

.sign__button__text {
  margin-left: 8px;
}

.delete__icon {
  margin-right: 9px;
  svg {
    width: 11px;
    height: 16px;
  }
}
.delete__text {
  color: #5B6880;
}

@media screen and (max-width: 768px) {
  .advancedSearch {
    &__button {
      margin-left: auto;
      margin-top: 10px;
    }

    &__field {
      min-width: 100%;
    }
  }

  .searchField {
    margin-right: 0;
  }
}
.searchText {
  position: absolute;
  right: 40px;
  display: flex;
  gap: 50px;
  span {
    color: #5B6880;
    font-size: 16px;
  }
}
.tabs{
  div {
    div {
      display: flex;
      gap: 25px;
      margin-bottom: 0;
      button {
        min-width: fit-content;
        font-size: 14px;
      }
    }
  }
}