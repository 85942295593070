$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.wrapper{
	border: 1px solid #DBE8FF;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	overflow: hidden;
}

.table {
	width: 100%;

	thead {
		background: $grey-100;
	}

	tbody {
		border-collapse: collapse;

		td{
			cursor: pointer;
		}

		tr{
			background: #fff;
			transition: all ease-in-out 0.2s;

			&:hover{
				background-color: $light-grey;
			}
		}
	}

	th {
		color: $white;
		padding: 8px;
		font-weight: $bold-weight;
		white-space: normal;
		word-break: break-all;
		text-align: left;
	}

	td {
		padding: 8px;
	}

	tr {
		&:not(:last-child){
			border-bottom: 1px solid #DBE8FF;
		}

		&:nth-child(even) {
			background: #F9FBFF;
		}
	}
}