$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.row {
  display: flex;
  gap: 16px;
  width: 100%;
  max-width: 90vw;
  margin-bottom: 15px;
}
.block {
  border: 1px solid #E3E3E3;
  height: 96px;
  flex: 1;
  border-radius: 8px;
  padding: 20px 10px;
  position: relative;
}
.blockEmpty {
  flex: 1;
  padding: 20px 10px;
}
.information {
  flex: 2;
  border-left: 3px solid #CDDEED;
  display: flex;
  align-items: center;
  gap: 16px;
}
.text {
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
  line-height: 20px;
  margin: 25px 0;
}
.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 15px;
}
.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
}
.hintIcon {
  width: 30px;
  height: 30px;
  margin-left: 16px;
}
.hintIconGreen {
  fill: #17b67c;
  margin-left: 8px;
}
.percent {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.green {
  background: #F0F9F4;
  color: #17B67C;
}
.purple {
  background: #F2F2FB;
  color: #7D75D2;
}
.grey {
  background: #F3F6FC;
  color: #4A566D;
}
.orange {
  background: #FCF3E7;
  color: #F3A126;
}
.red {
  background: #FBEFEF;
  color: #F04E47;
}
@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }
  .blockEmpty {
    display: none;
  }
}
.blockTitle {
  font-weight: 700;
  line-height: 20px;
  color: #4E525A;
  margin-bottom: 20px;
  margin-top: 30px;
}
.spanClass {
  font-size: 14px;
  font-weight: 400;
  color: #17B67C;
  margin-left: 15px;
  cursor: pointer;
}
.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
}