$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.select {
	position: relative;
}

.placeholder {
	color: $whiteBlue;
}

.values {
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
}

.valuesFrame {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #d3dff0;
	padding: 10px 20px;
	border-radius: 4px;
	min-height: 1rem;
	width: 100%;
	&[aria-disabled="true"] {
		background-color: #f9fbff;
		cursor: not-allowed;
	}
}

.cross {
	cursor: pointer;
	img {
		width: 20px;
	}
}
.badge{
	color: white;
	text-transform: lowercase;
	background: #AAB8D1;
	border-radius: 4px;
	padding: 4px 8px;
	display: flex;
	gap: 8px;
}

.closeIcon {
	width: 10px;
	height: 10px;
	fill: white;
}