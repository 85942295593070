$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.table {
  width: 100%;
  border-radius: 8px 8px 0 0;
  margin-top: 12px;

  @media screen and (max-width: 768px) {
    width: 100%;
    overflow-x: auto;
  }

  thead {
    background: #5b6880;
    color: white;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;

    th {
      text-align: left;
      padding: 11px 11px;
      font-size: 13px;
      line-height: 13.2px;
      word-break: break-word;

      &:first-child {
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  tbody {
    tr {
      height: 40px;
      background: #f9fbff;
      border: 1px solid #d3dff0;

      &:hover {
        background-color: $light-grey !important;
      }

      td {
        font-family: Inter, sans-serif;
        padding: 11px 0 11px 11px;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #1d293f;
        width: max-content;
        word-break: break-word;

        &:nth-child(5) {
          max-width: 100px;
        }

        &:nth-child(6) {
          max-width: 20px;
        }

        &:last-child {
          padding: 11px 11px 11px 0;
        }

        &:hover {
          cursor: pointer;
        }
        text-align: start;
      }

      &:nth-child(even) {
        background: #f9fbff;
      }

      &:nth-child(odd) {
        background: white;
      }
    }
  }
}

.td-cursor-pointer {
  cursor: pointer;
}
.td-padding-left {
  padding-left: 10px;
}

.treeView {
  margin-left: 10px;
}

.active {
  background-color: $whiteBlue !important;
}

.noActive {
  background-color: $white !important;
}

tbody {
  tr.active:hover {
    background-color: $whiteBlue !important;
  }
}

.tableLoading {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  /* Secondary Text */
  color: #5b6880;
  width: 40vw;
}

.messageCol {
  height: 200px;
  text-align: center;
  width: 40vw;
}

.list {
  padding: 0;
  margin: 0;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 4px 4px 20px 4px rgba(73, 98, 135, 0.1);
  cursor: pointer;

  li {
    padding: 0 12px;
    height: 28px;
    display: flex;
    gap: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    /* or 14px */
    align-items: center;
    white-space: nowrap;
    /* Secondary Text */
    color: #5b6880;

    &:hover {
      background: #e7eef8;
    }
  }

}