$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.modal {
  padding: 32px;
  max-width: 1216px;
  width: 90vw;
  display: block;
  margin-bottom: 50px;
  margin-top: 50px;
  margin: 100px auto;
}

.header {
  display: flex;

  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    color: $darkBlue
  }
}

.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 200%;
  color: $darkBlue
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.container {
  display: flex;
  gap: 40px;
}

.name {
  font-weight: $bold-weight;
  color: $darkBlue;
}

.titles {
  margin-right: 20px
}

.values {
  color: #5b6880
}

.flexBlock {
  color: #5b6880;
  display: inline-flex;
  gap: 20px;
}

.updateButton {
  cursor: pointer;
  color: #17b67c;
  display: flex;
  align-items: center;
}

.label {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.refreshIcon {
  fill: #17b67c;
  margin-right: .5rem;
}

.hintIcon {
  fill: #17b67c;
  margin-left: 8px;
}

.content {
  width: 100%;
  color: $grey-100;
}

.information {
  margin-bottom: 24px;
  div {
    width: 33%;
  }
}

.mb20 {
  margin-bottom: 20px;
}

.button {

}