$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.wrapper {
  background: #fff;
  box-shadow: 4px 4px 20px 4px rgba(73, 98, 135, 0.1);
  border-radius: 8px;

  padding: 16px 20px;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  color: $grey-100;

  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.subtitle {
  color: $whiteBlue;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.rating {
  font-size: 14px !important;
  color: $green !important;
}

.text {
  color: $grey-100;

  font-size: 14px;
  line-height: 16px;
  font-weight: 400;

  &_deleted{
    font-style: italic;
  }

  &_null{
    font-style: italic;
  }
}

.footer_wrapper {
  display: flex;
  gap: 16px;
}

.edit_button {
  color: $green;

  svg {
    fill: $green;
  }
}

.delete_button {
  color: $red;
  padding: 8px 12px;

  svg {
    fill: $red;
  }
}

.deleted_contract {
  display: block;
  text-align: right;

  color: $white;

  font-size: 14px;
  font-weight: 700;
  line-height: 18px;

  span{
    padding: 2px 12px;
    background-color: $whiteBlue;
  }
}

.delete_review {
  display: flex;
  justify-content: end;
}