$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.ul {
	z-index: 1001;
	position: absolute;
	background: #ffffff;
	border-radius: 8px;
	box-shadow: 4px 4px 20px 4px rgba(73, 98, 135, 0.1);
	right: 0;
	cursor: pointer;
	padding-left: 0;

	li {
		padding: 0 12px;
		height: 28px;
		display: flex;
		gap: 8px;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 120%;
		/* or 14px */
		align-items: center;
		white-space: nowrap;
		/* Secondary Text */
		color: #5b6880;
		&:hover {
			background: #e7eef8;
		}
	}
}

.menu {
	position: relative;
}

.actionBtn {
	width: max-content;
	padding: 6px;
	background: #f9fbff;
	border: 1px solid #d3dff0;
	box-sizing: border-box;
	border-radius: 4px;
	color: #aab8d1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	white-space: nowrap;
	gap: 6px;
}


.dropdownButton {
	border: 1px solid #D3DFF0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 4px;
	border-radius: 4px;
	color: #AAB8D1;
	background-color: #F9FBFF;
	font-weight: $middle-weight;
	padding: 6px;
	font-size: 14px;
	line-height: 16px;
	cursor: pointer;
}

.icon {
	fill: #AAB8D1;
	transform: rotate(-90deg);
	height: 10px;
	width: 10px;
}

.shown {
	display: block;
}

.hidden {
	display: none;
}
