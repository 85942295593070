$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.wrapper {
  background-color: $white-100;
  border: 1px solid $light-grey-200;

  border-radius: 8px;
  padding: 24px 20px;
  margin-bottom: 24px;

  display: flex;
  flex-direction: column;
  gap: 6px;
}

.title {
  color: $darkBlue;

  font-size: 18px;
  font-weight: 500;
}

.subtitle {
  color: $grey-400;

  font-size: 14px;
  font-weight: 400;
}

.list {
  margin-top: 8px;

  display: flex;
  flex-direction: column;

  gap: 14px;

  &__item {
    display: flex;
    align-items: center;

    .textContainer {
      width: 40%;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: start;

      .title {
        font-size: 14px;
      }

      .subtitle {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}

.status{
  display: flex;
  align-items: center;

  gap: 8px;

  &__text{
    width: 200px;

    &.success {
      color: $green;
    }

    &.pending {
      color: $grey-400;
    }

    &.failed {
      color: $red;
    }
  }

  &__img{
    width: 14px;
    height: 14px;
  }

  &__buttonContainer{
    height: 32px;
    display: flex;
    align-items: center;
  }

  &__button{
    padding: 6px 12px;

    font-size: 14px;
    line-height: 16px;
    border-radius: 4px;
  }

  &__checkboxText{
    margin-left: 6px;

    font-size: 12px;
    line-height: 14px;
    font-weight: 300;

    font-style: italic;
  }

  //@extend .error;
}