@import '../../../../../features/contracts/table/contractsTable.module';

@mixin cell {
  text-align: center;
  max-width: max-content;
}

.table {
  max-height: 300px;
  overflow: auto;

  thead {
    th {
      @include cell;
      min-width: 50px;
    }
  }
  tbody {
    tr {
      &:nth-child(even) {
        background: #EFF3FC;
      }

      td {
        @include cell;

        &:hover {
          cursor: auto;

          &:last-child, &:nth-last-child(2) {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.view__td {
  font-size: 13px;
  cursor: pointer;
}

.icon {
  margin-right: .5rem;
}
