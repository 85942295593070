$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.container {
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  position: relative;

  @media (max-width: 575.9px) {
    width: 90%;
  }

  @media (min-width: 576px) and (max-width: 767.9px) {
    width: 576px;
  }

  @media (min-width: 768px) and (max-width: 991.9px) {
    width: 768px;
  }

  @media (min-width: 992px) and (max-width: 1199.9px) {
    width: 992px;
  }

  @media (min-width: 1200px) {
    width: 1200px;
  }
}

//header
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 1000;

  &__logo {
    img {
      display: block;

      @media (min-width: 992px) and (max-width: 1199.9px) {
        width: 120px;
        height: 48px;
      }

      @media (min-width: 1200px) {
        width: 146px;
        height: 52px;
      }
    }
  }

  &__ecosystem {
    position: relative;

    &Icon {
      cursor: pointer;
    }

    &Array {
      margin-top: 10px;
      position: absolute;
      width: 428px;
      padding: 20px;
      background-color: #fff;
      opacity: 1;
      transition: visibility .3s, opacity .3s linear;
      box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
      border-radius: 10px;

      li {
        a {
          display: flex;
          gap: 16px;
          padding: 12px;

          &:hover {
            background-color: #e9eef1;
            border-radius: 10px;

            p {
              color: $green;
            }
          }

          h5 {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $darkBlue;
          }

          p {
            line-height: 20px;
            color: $grey-500;
          }
        }
      }
    }
  }

  &__nav {
    ul {
      padding: 0;
      margin: 0;
      display: flex;
      gap: 6px;

      a {
        color: #fff;
        font-family: "Inter Medium", sans-serif;
        font-weight: 500;

        @media (min-width: 992px) and (max-width: 1199.9px) {
          font-size: 14px;
          line-height: 16px;
          padding: 0 8px;
        }

        @media (min-width: 1200px) {
          font-size: 16px;
          line-height: 18px;
          padding: 0 12px;
        }

        &:hover {
          color: $green;
        }
      }
    }
  }

  &__lang {
    font-family: "Inter Medium", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: #fff;
  }
}

.headerMobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 1000;

  &__logo {
    img {
      display: block;
    }
  }

  &__icon {
    color: #fff !important;
    width: 32px;
    height: 32px;
  }
}

.mobileMenu {
  position: fixed;
  width: 100%;
  height: 100%;
  left: -200%;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  background-color: $darkBlue;
  z-index: 1000;
  overflow-y: scroll;

  &Visible {
    left: 0;
  }

  .headerMobile {
    position: relative;
    top: 0;
    margin-top: 20px;
  }

  &__menu {
    margin-top: 40px;

    li {
      padding: 16px;
      text-align: center;
    }

    a {
      font-family: "Inter Medium", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      color: #fff;
    }
  }
}

//hero
.hero {
  background-image: url('../../assets/home/Slide-1.webp');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 575.9px) {
    height: 500px;
  }

  @media (min-width: 576px) and (max-width: 767.9px) {
    height: 600px;
  }

  @media (min-width: 768px) and (max-width: 991.9px) {
    height: 600px;
  }

  @media (min-width: 992px) and (max-width: 1199.9px) {
    height: 750px;
  }

  @media (min-width: 1200px) {
    height: 750px;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
  }

  &__title {
    font-weight: 700;
    color: #fff;
    font-family: "Montserrat Alternates", sans-serif;
    line-height: 1;
    text-align: center;

    @media (max-width: 575.9px) {
      font-size: 24px;
      line-height: 26px;
    }

    @media (min-width: 576px) and (max-width: 767.9px) {
      font-size: 32px;
      line-height: 36px;
    }

    @media (min-width: 768px) and (max-width: 991.9px) {
      font-size: 56px;
      line-height: 64px;
    }

    @media (min-width: 992px) and (max-width: 1199.9px) {
      font-size: 56px;
      line-height: 64px;
    }

    @media (min-width: 1200px) {
      font-size: 64px;
      line-height: 72px;
    }
  }

  &__card {
    position: absolute;
    width: 100%;
    background: #fff;
    box-shadow: 0 10px 34px hsla(0, 0%, 82.4%, .5);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 575.9px) {
      flex-direction: column;
      padding: 20px;
      gap: 24px;
      bottom: -30%;
    }

    @media (min-width: 576px) and (max-width: 767.9px) {
      flex-direction: column;
      padding: 20px;
      gap: 24px;
      bottom: -30%;
    }

    @media (min-width: 768px) and (max-width: 991.9px) {
      flex-direction: row;
      padding: 26px;
      gap: 24px;
      bottom: -10%;
    }

    @media (min-width: 992px) and (max-width: 1199.9px) {
      flex-direction: row;
      padding: 30px;
      bottom: -10%;
    }

    @media (min-width: 1200px) {
      flex-direction: row;
      padding: 34px;
      bottom: -10%;
    }

    &Item {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 6px;
    }

    h2 {
      font-weight: 700;
      color: #17b67c;
      text-align: center;
      font-family: "Montserrat Alternates", sans-serif;

      @media (max-width: 575.9px) {
        font-size: 32px;
        line-height: 38px;
      }

      @media (min-width: 576px) and (max-width: 767.9px) {
        font-size: 36px;
        line-height: 42px;
      }

      @media (min-width: 768px) and (max-width: 991.9px) {
        font-size: 40px;
        line-height: 48px;
      }

      @media (min-width: 992px) and (max-width: 1199.9px) {
        font-size: 48px;
        line-height: 56px;
      }

      @media (min-width: 1200px) {
        font-size: 48px;
        line-height: 56px;
      }
    }

    p {
      text-align: center;
      color: #283339;

      @media (max-width: 575.9px) {
        font-size: 18px;
        line-height: 20px;
      }

      @media (min-width: 576px) and (max-width: 767.9px) {
        font-size: 20px;
        line-height: 22px;
      }

      @media (min-width: 768px) and (max-width: 991.9px) {
        font-size: 24px;
        line-height: 26px;
      }

      @media (min-width: 992px) and (max-width: 1199.9px) {
        font-size: 24px;
        line-height: 26px;
      }

      @media (min-width: 1200px) {
        font-size: 24px;
        line-height: 26px;
      }
    }
  }
}

//info
.info {
  position: relative;
  z-index: 100;

  @media (max-width: 767.9px) {
    padding-top: 220px;
    padding-bottom: 50px;
  }

  @media (min-width: 768px) {
    padding-top: 150px;
    padding-bottom: 100px;
  }

  &__wrapper {
    width: 90%;
    margin: 0 auto;
  }

  &__slider {
    width: 280px !important;
    height: 250px;
    position: relative;
    box-shadow: 4px 4px 20px 4px rgba(73, 98, 135, .1);
    border-radius: 8px;
    display: block !important;
    margin: auto;

    img {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    span {
      position: absolute;
      top: 24px;
      left: 24px;
      right: 24px;
      bottom: 24px;
      font-weight: 500;
      font-size: 18px;
      color: #1d293f;
    }

    a {
      position: absolute;
      display: block;
      color: #aab8d1;
      font-weight: 700;
      font-size: 18px;
      text-decoration: none;
      bottom: 66px;
      left: 24px;
      z-index: 10;
    }
  }

  &:after{
    content: "";
    background-color: $green;
    position: absolute;
    z-index: 1;
    transform: skewY(-5deg);
    width: 40%;
    height: 48px;

    @media (max-width: 767.9px) {
      width: 272px;
      height: 26px;
      left: 0;
    }

    @media (max-width: 575.9px) {
      bottom: -20px;
    }

    @media (min-width: 576px) and (max-width: 767.9px) {
      bottom: -30px;
    }

    @media (min-width: 768px) and (max-width: 1199.9px) {
      bottom: -50px;
    }

    @media (min-width: 1200px) {
      bottom: -60px;
    }
  }

  &:before{
    content: "";
    background-color: $green;
    position: absolute;
    transform: skewY(-5deg);
    width: 10%;
    height: 48px;
    left: 2%;
    mix-blend-mode: multiply;
    z-index: 10;

    @media (max-width: 767.9px) {
      width: 80px;
      height: 26px;
    }

    @media (max-width: 575.9px) {
      bottom: -40px;
    }

    @media (min-width: 576px) and (max-width: 767.9px) {
      bottom: -50px;
    }


    @media (min-width: 768px) and (max-width: 1199.9px) {
      bottom: -80px;
    }

    @media (min-width: 1200px) {
      bottom: -100px;
    }
  }
}

//employer
.grid {
  padding: 60px 0;

  &__grid {
    display: grid;
    grid-column-gap: 42px;
    grid-row-gap: 42px;

    @media (min-width: 768px) and (max-width: 1199.9px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(7, 1fr);
    }

    @media (min-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }
  }

  &__line {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 1px;
      height: 90%;
      background-color: #E5E7EB;
      z-index: 1;

      @media (max-width: 575.9px) {
        display: none;
      }

      @media (min-width: 576px) {
        top: 0;
        left: -30px;
      }

      @media (min-width: 768px) and (max-width: 991.9px) {
        top: 8%;
        left: -30px;
      }

      @media (min-width: 992px) and (max-width: 1199.9px) {
        top: 6%;
        left: -30px;
      }

      @media (min-width: 1200px) {
        top: 8%;
        left: -30px;
      }
    }

    &:after{
      content: "";
      position: absolute;
      z-index: 1;
      width: 4px;
      height: 50px;
      background-color: $green;

      @media (max-width: 575.9px) {
        display: none;
      }

      @media (min-width: 576px) {
        top: 0;
        left: -30px;
      }

      @media (min-width: 768px) and (max-width: 991.9px) {
        top: 8%;
        left: -30px;
      }

      @media (min-width: 992px) and (max-width: 1199.9px) {
        top: 6%;
        left: -30px;
      }

      @media (min-width: 1200px) {
        top: 8%;
        left: -30px;
      }
    }
  }

  &__block {
    &0 {
      display: flex;
      gap: 16px;
      justify-content: center;
      flex-direction: column;
    }

    @media (min-width: 768px) and (max-width: 1199.9px) {
      &0 {
        grid-area: 1 / 1 / 3 / 2;
      }
      &1 {
        grid-area: 3 / 1 / 5 / 2;
      }
      &2 {
        grid-area: 2 / 2 / 4 / 3;
      }
      &3 {
        grid-area: 4 / 2 / 6 / 3;
      }
      &4 {
        grid-area: 5 / 1 / 7 / 2;
      }
      &5 {
        grid-area: 6 / 2 / 8 / 3;
      }
    }

    @media (min-width: 1200px) {
      &0 {
        grid-area: 1 / 1 / 3 / 2;
      }
      &1 {
        grid-area: 3 / 1 / 5 / 2;
      }
      &2 {
        grid-area: 2 / 2 / 4 / 3;
      }
      &3 {
        grid-area: 4 / 2 / 6 / 3;
      }
      &4 {
        grid-area: 1 / 3 / 3 / 4;
      }
      &5 {
        grid-area: 3 / 3 / 5 / 4;
      }
    }
  }

  &__title {
    font-family: "Montserrat Alternates";
    font-weight: 700;
    color: #1d293f;

    @media (max-width: 575.9px) {
      font-size: 32px;
      line-height: 46px;
    }

    @media (min-width: 576px) and (max-width: 767.9px) {
      font-size: 36px;
      line-height: 48px;
    }

    @media (min-width: 768px) and (max-width: 991.9px) {
      font-size: 38px;
      line-height: 50px;
    }

    @media (min-width: 992px) and (max-width: 1199.9px) {
      font-size: 40px;
      line-height: 54px;
    }

    @media (min-width: 1200px) {
      font-size: 42px;
      line-height: 56px;
    }
  }

  &__subtitle {
    color: #000;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    line-height: 24px;
  }

  &__card {
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25)) drop-shadow(0 22.0074px 24.1177px rgba(0, 0, 0, .058643)) drop-shadow(0 9.14073px 10.0172px rgba(0, 0, 0, .045));
    background: #fff;
    border-radius: 24px;
    padding: 24px;

    @media (max-width: 768px) {
      filter: none;
    }

    img {
      width: 42px;
      height: 42px;
    }

    p {
      font-family: "Montserrat Alternates", sans-serif;
      color: #000;
      font-size: 18px;
      line-height: 22px;
    }

    span {
      font-family: "Inter", sans-serif;
      text-align: left;
      color: $grey-500;
      line-height: 20px;
      font-size: 16px;
    }
  }
}

.employer {
  position: relative;
  z-index: 10;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f9fafb;
    -webkit-transform: skewY(-5deg);
    transform: skewY(-5deg);
    z-index: 1;
  }

  &:after{
    content: "";
    background-color: $green;
    position: absolute;
    z-index: 1;
    transform: skewY(-5deg);

    @media (max-width: 768px) {
      width: 272px;
      height: 26px;
      right: 0;
      bottom: 0;
    }

    @media (min-width: 768px) {
      width: 40%;
      height: 48px;
      right: 0;
      bottom: -50px;
    }

    @media (min-width: 1200px) {
      bottom: -12px;
    }
  }
}

.worker {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f9fafb;
    -webkit-transform: skewY(-5deg);
    transform: skewY(-5deg);
    z-index: 1;
  }

  &:after{
    content: "";
    background-color: $green;
    position: absolute;
    z-index: 1;
    transform: skewY(-5deg);

    @media (max-width: 575.9px) {
      width: 272px;
      height: 26px;
      left: 0;
      bottom: -20px;
    }

    @media (min-width: 576px) {
      width: 40%;
      height: 48px;
      left: 0;
      bottom: -40px;
    }

    @media (min-width: 768px) and (max-width: 1199.9px) {
      bottom: -50px;
    }

    @media (min-width: 1200px) {
      bottom: -60px;
    }
  }
}

//form
.form {
  padding: 40px 0;
  margin-top: 60px;

  &__wrapper {
    padding: 24px;
    background: #fff;
    box-shadow: 4px 4px 20px 4px rgba(73, 98, 135, .1);
    border-radius: 4px;
  }

  &__form {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 767.9px) {
      flex-direction: column;
      gap: 16px;
    }

    h4 {
      font-family: "Montserrat Alternates", sans-serif;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #1d293f;
      margin-right: 10%;
      text-align: center;
    }
  }

  &__footerTitle {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: $darkBlue;
  }

  &__footerText {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #5b6880;
  }

  &__footerWrapper {
    display: flex;
    gap: 8px;

    @media (max-width: 575.9px) {
      flex-direction: column;
    }
  }

  &__footerContent {
    display: flex;
    flex-direction: column;
  }

  &__footerContactWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__footerPhone {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

//footer
.footer {
  background-color: $darkBlue;

  &__br {
    background-color: $green;
    height: 1px;
    width: 100%;
  }

  &__wrapper {
    padding: 50px 0;

    @media (max-width: 575.9px) {
      padding: 20px 0;
    }

    @media (min-width: 576px) and (max-width: 767.9px) {
      padding: 30px 0;
    }
  }

  &__content {
    margin: 50px 0;
    display: flex;

    @media (max-width: 575.9px) {
      flex-direction: column;
      margin: 20px 0;
      gap: 30px;
    }

    @media (min-width: 576px) and (max-width: 767.9px) {
      flex-wrap: wrap;
      gap: 20px;
      margin: 30px 0;
    }

    @media (min-width: 768px) and (max-width: 991.9px) {
      flex-wrap: wrap;
      gap: 30px;
    }
  }

  &__contentItem {
    display: flex;
    flex-direction: column;
    flex: 1;

    @media (min-width: 576px) and (max-width: 767.9px) {
      flex: 48%;
    }

    @media (min-width: 768px) and (max-width: 991.9px) {
      &:nth-child(1), &:nth-child(3), &:nth-child(2) {
        flex: 30%;
      }
    }
  }

  &__title {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: .17em;
    color: #fff;
    text-transform: uppercase;
  }

  &__text {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
  }

  &__numberPhone {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 51px;
    color: $green;
  }

  &__list {
    & > li:not(:last-child) {
      margin-bottom: 14px;
    }
  }

  &__qr {
    display: flex;
    align-items: center;
    gap: 16px;


    @media (max-width: 575.9px) {
      flex-direction: column;
      align-items: start;
    }

    img {
      border-radius: 8px;

      @media (max-width: 575.9px) {
        width: 240px;
        height: 240px;
      }
    }

    span {
      font-size: 12px;
      line-height: 14px;
    }
  }

  &__logo {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__difficult {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 575.9px) {
      flex-direction: column;
      gap: 20px;
    }

    p {
      font-family: Montserrat Alternates, sans-serif;
      font-weight: 700;
      font-size: 38px;
      line-height: 46px;
      color: #fff;

      @media (max-width: 575.9px) {
        text-align: center;
      }
    }

    span {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      max-width: 320px;
      display: inline-block;

      @media (max-width: 575.9px) {
        text-align: center;
        max-width: 100%;
        display: inline-block;
      }
    }
  }

  &__copyright {
    padding: 20px 0;
    display: flex;
    justify-content: end;

    span {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #fff !important;
    }

    a {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
  }
}

.feedback {
  padding: 16px;

  &__title {
    font-family: "Inter Bold", sans-serif;
    font-style: normal;
    text-align: left;
    font-size: 32px;
    line-height: 38px;
    color: #1d293f;
  }

  &__subtitle {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    color: #1d293f;
  }

  &__input {
    border-radius: 4px;
    background: $white-100;
    border: 1px solid $light-grey-200;
    color: $darkBlue;
    outline-color: $darkBlue;
    box-sizing: border-box;
    padding: 8px 16px;

    &::placeholder {
      color: $grey-200;
      opacity: 1;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }
  }

  &__select {
    input {
      background: $white-100;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__footer {
    display: flex;
    align-items: center;
    gap: 16px;

    @media (max-width: 575.9px) {
      flex-direction: column;
    }

    & > button {
      height: 48px;
    }
  }

  &__attache_btn {
    height: 48px;
    border: 1px solid $green;
    display: flex;
    align-items: center;
    padding: 0 22px;
    cursor: pointer;

    span {
      color: $green;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &Item {
      display: flex;
      justify-content: space-between;
      gap: 8px;

      & > div {
        display: flex;
        align-items: center;
        gap: 6px;

        &:first-child {
          color: #5b6880;
        }

        &:last-child {
          cursor: pointer;
          color: $red;
          font-weight: 700;
        }
      }
    }
  }
}