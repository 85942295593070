$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.col {
	display: flex;
	flex-direction: column;
	gap: 32px;
}

.selectCol {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.label {
	display: block;
	margin-bottom: 8px;
}

.button {
	width: 130px;
	align-items: center;

	&Red {
		@extend .button;
		color: #EB5757;
	}
}

.iconStatus{
	width: 26px !important;
	height: 26px !important;
	margin: 0 !important;
}

.icon {
	
	width: 12px;
	height: 12px;
	margin-right: 8px;
	&Green {
		@extend .icon;
		fill: $green !important;
	}

	&Red {
		@extend .icon;
		fill: #EB5757 !important;
	}
}

.row {
	display: flex;
	gap: 32px;
}

.buttonBlock {
	margin-top: 64px;
	display: flex;
	justify-content: flex-end;
}

.error {
	color: #E65332
}
.errorButton {
	color: $red;
	display: flex;
	justify-content: flex-end;
}
.mb {
	margin-bottom: 24px;
}

.rating {
	color: $green !important
}

.loadingWrapper{
	margin-top: 24px;
	border: 2px solid #17B67C;
	border-radius: 15px;
	padding: 20px;
}