$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.form {
  display: grid;
  grid-row-gap: 56px;
  margin-top: 44px;
  width: 100%;
}
.fields__wrapper {
  display: flex;
  justify-content: space-between;
}
.field__input {
  width: 100%;
  margin-right: 16px;
}
.fieldAndButton {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 32px;
  align-items: flex-start;

  Button {
    margin-top: 26px;
  }
}

.fieldAndRemoveButton {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-column-gap: 32px;
  align-items: flex-start;
  Button {
    margin-top: 26px;
  }
}

.fieldAndRemoveButtonIIN {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-column-gap: 32px;
  align-items: flex-start;
  width: calc(71% - 96px);
  Button {
    margin-top: 26px;
  }
}

.fields__item__no__button {
  width: 100%;
  margin-left: 32px;
}

.fields__item__no__button__left {
  width: 100%;
}

.fields_union_name {
  width: 100%;
  padding-top: 24px;
}

.radio {
  .radio + label {
    padding: 0 0 24px 24px;
  }
}

.fields__iin__wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
}

.fields {
  display: grid;
  grid-column-gap: 32px;
  grid-template-columns: 1fr 2fr;
  align-items: flex-start;

  &_verticalCenter {
    align-items: center;
  }
}

.threeFields {
  grid-template-columns: 32.5% 30.7% 30.6%;
}

.icon {

  width: 12px;
  height: 12px;
  margin-right: 8px;
  &Green {
    @extend .icon;
    fill: $green;
  }

  &Red {
    @extend .icon;
    fill: #EB5757;
  }
}

.button {
  width: 240px;
  align-items: center;
  padding-left: 0;
  margin-top: 20px;
  font-weight: 800;
  &Red {
    @extend .button;
    color: #EB5757;
    width: 100px;
  }
}

.twoFields {
  display: grid;
  grid-column-gap: 30px;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  &_verticalCenter {
    align-items: center;
  }
}

.mbFields {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

.fieldsGuarantee {
  margin-bottom: 24px;
  margin-top: 24px;
  &:last-child {
    margin-bottom: 0;
  }
}

.fieldsFullWidth {
  display: grid;
  grid-column-gap: 32px;
  grid-template-columns: 3fr;
}

.fundingVolume {
  .input {
    height: 123px;
  }
}

.securityFundingVolume {
  width: 296px;
  max-width: 33%;
}

.actions {
  display: flex;
  justify-content: flex-end;
  grid-column-gap: 60px;
}

.subTitle {
  margin-bottom: 8px;
  color: $grey-400;
  font-size: 18px;
  font-weight: $middle-weight;
  margin-top: 24px;
}