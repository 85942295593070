$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.dialog {
  width: 645px;
  border-radius: 0 !important;
  &__delete {
    width: 452px;
    border-radius: 0 !important;
  }
  &__save {
    width: 520px;
    border-radius: 0 !important;
  }
  &__new__template {
    width: 1216px;
    border-radius: 0 !important;
    max-width: none !important;
  }
}

.title {
  font-family: 'Inter Bold', sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130% !important;
  color: $darkBlue !important;
  text-align: center;
  &__new__template {
    text-align: left;
    font-family: 'Inter Bold', sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: $darkBlue;
    margin-bottom: 30px;
  }
  &__save {
    margin-bottom: 16px;
  }
}

.reset__title {
  width: 400px !important;
  margin: 30px auto 20px !important;
  font-family: 'Inter Bold', sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 130% !important;
  color: $darkBlue !important;
  text-align: center !important;
}

.save__input__wrapper {
  width: 300px
}

.sub__title {
  font-family: 'Inter Bold', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  color: $grey-100;
  margin-bottom: 24px;
}

.button {
  font-family: 'Inter Bold', sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 130% !important;
  color: $darkBlue !important;
  text-transform: none !important;
  padding: 5px !important;
}

.delete__button {
  color: $red !important;
}

.back__button {
  color: $green !important;
}

.reset__button {
  color: $whiteBlue !important;
}

.text {
  font-size: 16px;
  cursor: pointer;
  padding: 5px;
  div {
    font-size: 16px;
  }

  &__left {
    max-width: 200px;
    word-break: break-all;
  }

  &__wrapper {
    width: 100%;
  }

  &:nth-child(even){
    background-color: $white-100;
  }
}

.creation__date {
  color: $whiteBlue;
  margin-right: 28px;
}

.confirm__button {
  background-color: $green !important;
  box-shadow: none !important;
  border-radius: 2px !important;
  margin-bottom: 24px !important;
  margin-top: 12px !important;
  &:hover {
    background-color: $green !important;
    box-shadow: none !important;
    border-radius: 2px !important;
  }
  &__save {
    height: 40px;
    margin-top: 6px !important;
  }
}

.label__root {
  max-width: fit-content !important;
}

.option {
  &__block {
    margin-bottom: 28px;
  }
  &__title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 110%;
    color: $darkBlue;
    margin-bottom: 16px;
  }
}

.checkbox__root {
  svg {
    border-radius: 4px !important;
    path {
      border-radius: 4px !important;
    }
  }
}

.button__wrapper {
  margin-right: 21px;
}