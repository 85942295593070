$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.tabOuter {
  &__list {
    border-bottom: 1px solid $light-grey-200;
    margin-bottom: 24px;
  }

  &__item {
    color: $whiteBlue;

    padding: 14px 12px;

    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    text-transform: uppercase;

    &:global(.Mui-selected) {
      color: $darkBlue;
      border-bottom: 1px solid $darkBlue;
    }
  }
}

.tabInner {
  &__item {
    color: $darkBlue;

    padding: 10px 16px;

    font-size: 16px;
    line-height: 20px;
    font-weight: 500;

    &:global(.Mui-selected) {
      border-radius: 4px;
      background-color: $grey-300;
    }
  }
}

.content_wrapper {
  padding: 24px 0;
}

.badge {
  width: 300px;
  padding: 12px;
  border: 1px solid $light-grey-200;
  border-radius: 16px;

  & > * {
    margin: 0;
    padding: 0;
  }

  &__value {
    color: $darkBlue;

    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
  }

  &__label {
    color: $grey-100;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}
.table{
  margin-top: 24px;
  width: 100%;

  @media screen and (min-width: 1000px) {
    max-width: calc(100vw - 300px);
  }

  .tagContainer{
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
  }
  .icon {
    width: 10px;
    height: 10px;
    margin-right: 14px;

    fill: $darkBlue;

    transform: rotate(-90deg);

    &__expanded {
      transform: rotate(90deg);
    }
  }
}

.secondary{
  color: #ADADAD;
  font-size: 12px;
}

.agroTable{
  width: 50%;

  @media (min-width: 1000px) and (max-width: 1200px) {
    width: 100%;
  }
}