$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.radioPanel {
  align-items: center;
  background: #ffffff;
  border: 1px solid #D2D4D9;
  box-shadow: 4px 4px 20px 15px rgba(73, 98, 135, 0.1);
  border-radius: 25px;
  max-width: 100%;
  width: 70%;
  padding: 30px;
  margin: 0px 0px 40px 0px;


  img {
    margin-right: 15px;
  }

  label {
    margin-right: 35px;
    color: rgba(29, 41, 63, 1);
  }
}

.ident {
  margin-top: 45px;
}

.identCaptcha {
  margin-top: 15px;
}

.bolt {
  font-weight: bold;
  padding: 3% 0% 0% 0%;
}

.boltSpetial {
  font-weight: bold;
  padding: 9.5% 0% 0% 0%;
}

.boltPadding {
  font-weight: bold;
  padding: 15% 0% 0% 0%;
}

.tdBorder {
  padding: 20px;
}

.inputWith {
  padding-left: 10px;
  border-radius: 4px;
  width: 20%;
}

@media (max-width: 1000px) {
  label {
    margin-right: 10px;
  }

  .tdBorder {
    display: contents;
  }

  .bolt {
    font-weight: bold;
    padding: 0;
  }

  .boltSpetial {
    padding: 3% 0% 0% 0%;
  }

  .radioPanel {
    display: contents;
  }
}
