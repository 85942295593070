$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

@import 'src/features/contracts/additionalContracts/AdditionalContractStyles.module';

.header {
  display: flex;

  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    color: $darkBlue
  }
}
.closeButton {
  display: flex;
  margin: 2rem 0 0 auto;
  padding-left: 3rem;
  padding-right: 3rem;
}
.title {
  font-weight: 400;
  font-size: 18px;
  line-height: 110%;
  color: #9CA5B6;
  margin-top: 25px;
  margin-bottom: 25px;
}
.green {
  color: #17B67C;
}
.gray {
  color: #4E525A;
  margin-bottom: 5px;
}
.red {
  color: #EB5757;
}
.yellow {
  color: #FFB900;
}
.gray400 {
  color: $grey-400;
  margin-bottom: 5px;
}
.openContractButton {
  margin: 25px 0;
}
.list {
  margin-left: 20px;
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;
    div {
      color: #5B6880;
    }
    span {
      font-weight: 700;
    }
  }
  li::before {
    content: "•";
    position: absolute;
    left: 0;
    top: 0;
    font-size: 25px;
    color: #AAB8D1;
    line-height: 20px;
  }
  li.red_li:before {
    color: #EB5757;
  }
  li.green_li:before {
    color: #17B67C;
  }
  li.yellow_li:before {
    color: #FFB900;
  }
  li::after {
    content: "";
    position: absolute;
    background-color: #AAB8D1;
    width: 1px;
    height: 30px;
    left: 7px;
    top: 23px;
  }
  li:last-child::after {
    background-color: #fff;
  }
}