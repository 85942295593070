$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

@mixin titile {
    font-size: 24px;
    font-weight: 400;
}

.title_sm_inter {
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: #1D293F;
}

.subtitle_inter {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #1D293F;
}



.title {
    @include titile;
    color: #1D293F;
}

.title_gray {
    @include titile;
    color: #5B6880;
}

@mixin subtitle_xl {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
}

.subtitle_xl {
    @include subtitle_xl;
}

.subtitle_xl_green {
    @include subtitle_xl;
    color: #17B67C;
}

.subtitle_xl_red {
    @include subtitle_xl;
    color: #EB5757;
}

.subtitle_xl_blue {
    @include subtitle_xl;
    color: #5B6880;
}

.subtitle {
    font-size: 14px;
    font-weight: bold;
    color: #1D293F;
}

.subtitle_xxl {
    font-family: 'Inter';
    font-size: 28px;
    font-weight: 500;
    line-height: 28px;
}

.subtitle_xl {
    font-family: 'Inter';
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
}


.text {
    font-size: 14px;
    font-weight: 400;
    color: #5B6880;
}

.text_button {
    font-size: 14px;
    font-weight: 400;
}

.text_button_green {
    font-size: 14px;
    font-weight: 400;
    color: #17B67C;
}

.text_button_green_s {
    font-size: 12px;
    font-weight: 400;
    color: #17B67C;
}


.text_button_red {
    font-size: 14px;
    font-weight: 400;
    color: #EB5757;
}

.text_button_red_s {
    font-size: 12px;
    font-weight: 400;
    color: #EB5757;
}

.text_m {
    font-size: 18px;
    font-weight: 400;
    color: #1D293F;
}

.text_sm {
    font-size: 12px;
    font-weight: 400;
    color: #5B6880;
}

.text_inter {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.text_inter_14 {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}

.subtitle_bold {
    font-size: 14px;
    font-weight: 700;
    color: #1D293F;
}

.title_24_400 {
    font-size: 24px;
    font-weight: 400;
    color: #1D293F;
}

.title_14_400_gray {
    font-size: 14px;
    font-weight: 400;
    color: #5B6880;
}

.title_24_700 {
    font-size: 24px;
    font-weight: 700;
    color: #1D293F;
}

.status_done_400_18_white_green {
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    background-color: #17B67C;
    padding: 4px 10px 4px 10px;
}

.status_done_400_18_white_red {
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    background-color: #EB5757;
    padding: 4px 10px 4px 10px;
}
