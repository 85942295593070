$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.actions {
  display: flex;
  margin-top: 16px;
  gap: 16px;
}

.actionsWrapper {
  justify-content: center;
  gap: 8px;
}

.select {
  display: grid;
  grid-row-gap: 32px;
}

.fieldLabel {
  font-weight: $bold-weight;
}

.hintIcon {
  fill: #17b67c;
  margin-left: 8px;
}

.label {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  label {
    margin-right: 35px;
  }
}

.refreshIcon {
  fill: #17b67c;
  margin-right: .5rem;
}

.updateButton {
  cursor: pointer;
  color: #17b67c;
  display: flex;
  align-items: center;
}

.disabled {
  pointer-events: none;
}

.dialog__paper {
  max-width: 975px !important;
  padding: 45px !important;
}

.dialog__title {
  padding: 0 !important;
  margin-bottom: 27px;
}

.dialog__content {
  padding: 0 !important;
}

.dialog__actions {
  margin-top: 53px;
  justify-content: center !important;
}

.company__warning__title {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  color: #1D293F;
  margin-bottom: 25px;
}

.column__title {
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  color: #5B6880;
  margin-bottom: 11px;
}

.column__key {
  font-weight: 700;
  font-size: 14px;
  line-height: 110%;
  color: #1D293F;
  margin-bottom: 7px;
}

.column__value {
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  color: #5B6880;
  margin-bottom: 7px;
}

.grid__line__root {
  margin-bottom: 40px !important;
}

@media (max-width: 1000px) {
  .actions {
    display: none;
  }
}