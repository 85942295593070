$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.contains {
  max-width: 95%;
  margin-top: 25px;
  font-size: 18px;
  line-height: 110%;
  color: $darkBlue;

  p {
    font-family: 'Inter Medium', sans-serif;
    margin: 0 0 10px 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 110%;
    color: $darkBlue
  }

  span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: $whiteBlue;
    margin-bottom: 3px;

    p {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: $grey-100;
    }
  }
}

.pseudoTable {
  height: 600px;
  overflow: hidden;
  border: 1px solid #D3DFF0;
  border-radius: 20px;
  overflow-y: auto;

  .pseudoTh {
    font-family: 'Inter Medium', sans-serif;

    p {
      margin-bottom: 3px;
    }

    &:nth-child(even) {
      align-items: flex-start;
      padding: 15px 20px;
      background: #f9fbff;
      border-bottom: 1px solid #D3DFF0;
      &:hover {
        background-color: $light-grey;
      }
    }

    &:nth-child(odd) {
      align-items: flex-start;
      padding: 15px 20px;
      background: white;
      border-bottom: 1px solid #D3DFF0;
      &:hover {
        background-color: $light-grey;
      }
    }
    &.harm {
      &:nth-child(even),
      &:nth-child(odd) {
        background: $light-blue;
        &:hover {
          background: $blue;
        }
      }
    }
    &:hover {
      cursor: pointer;
    }
  }

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      cursor: pointer;
      box-shadow: inset 0 0 6px $whiteBlue;
      margin: 20px 20px 20px 0;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $green;
    }
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}


@media screen and (max-width: 768px) {
  .contains {
    display: none;
  }
}