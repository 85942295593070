$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.dialog {
  min-width: 500px;
  max-width: 500px !important;
  padding-right: 0;
  padding-left: 0;
}

.sign__title {
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 130% !important;
  text-align: center !important;
  color: $darkBlue !important;
  padding-top: 65px !important;
}

.dialog__buttons__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textarea__wrapper {
  width: 100%;
  margin-bottom: 32px;
}

.reset__button__wrapper {
  margin-bottom: 20px;
}

.button {
  padding-top: 6px;
  padding-bottom: 6px;

  &Table {
    @extend .button;
    margin-top: 32px;
  }
}

.reset__button {
  font-size: 14px !important;
  color: $whiteBlue !important;
}