$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.dialog__template {
	max-width: 1028px !important;
	width: 1028px !important;
}

.button_etd {
	font-family: 'Inter' !important;
	font-weight: 400 !important;
	font-size: 16px !important;
	line-height: 130% !important;
	color: #5B6880 !important;
	padding: 0 !important;
	text-transform: none !important;
}

.back__button {
	color: #17B67C !important;
}

.header {
	margin-top: 15px;
	margin-bottom: 36px;
}

.etd__detailInformation {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.etd__title {
	font-weight: 400;
	font-size: 24px;
	line-height: 130%;
	color: #5B6880;
}

.id__wrapper {
	font-weight: 400;
	font-size: 14px;
	line-height: 140%;
	color: #9CA5B6;
}

.date__wrapper {
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
	color: #5B6880;
}

.edit__icon {
	width: 22px;
	height: 22px;
}

.etd_mini_plug {
	margin-right: 32px;
	background: #5B6880;
	color: #ffffff;
	padding: 2px 12px;
	font-weight: 400;
	font-size: 18px;
}

.requisites__container {
	margin-top: 24px !important;
	//margin-bottom: 58px !important;
}

.requisites__col__title {
	font-weight: 400;
	font-size: 18px;
	line-height: 110%;
	color: #9CA5B6;
	margin-bottom: 24px !important;
}

.requisites__col__key {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 110%;
	color: #1D293F;
}

.requisites__col__value {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	color: #5B6880;
}

.label {
	display: block;
	margin-bottom: 8px;
}

.icon {

	width: 12px;
	height: 12px;
	margin-right: 8px;

	&Green {
		@extend .icon;
		fill: $green;
	}

	&Red {
		@extend .icon;
		fill: #EB5757;
	}
}

.iconStatus{
	width: 26px !important;
	height: 26px !important;
	margin: 0 !important;
}

.button {
	width: 130px;
	align-items: center;

	&Red {
		@extend .button;
		color: #EB5757;
	}
}

.select__wrapper {
	margin-bottom: 24px;
	display: flex;
}

.buttonBlock {
	margin-top: 64px;
	display: flex;
	justify-content: flex-end;
}

.error {
	color: #E65332
}

.button__wrapper {
	margin-left: 30px;
}

.cause__text {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 110%;
	color: #1D293F;
	border: 1px solid #AAB8D1;
	padding: 16px;
	border-radius: 14px;
	margin-top: 16px;
}

// modals

.modal__dialog {
	max-width: 452px !important;
	width: 452px !important;
}

.modal__text {
	font-weight: 700;
	font-size: 24px;
	line-height: 130%;
	text-align: center;
	color: #1D293F;
	margin-bottom: 32px;
	margin-top: 10px;
}
.textarea__wrapper {
  width: 100%;
  margin-bottom: 32px;
}

.loadingWrapper{
	margin-top: 24px;
	border: 2px solid #17B67C;
	border-radius: 15px;
	padding: 20px;
}