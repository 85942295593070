$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.base_block {
  border: #D3DFF0 1px solid;
  border-radius: 2px;
  padding: 12px 16px 12px 16px;
}

.bottom_block {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  flex-grow: 1;
}


.top_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  gap: 4px;
  flex-wrap: wrap;
}

.row_container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex-grow: 1;

}

.main_container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
}

.grid_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
    @media (max-width: 717px) {
        grid-template-columns: 1fr 1fr;
    }
}

.flex_row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@mixin risk_container {
  width: fit-content;
  height: 28px;
  border-radius: 2px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.risk_container_medium {
  @include risk_container;
  background-color: #F3A12666;
  color: #F3A126;
}

.risk_container_high {
  @include risk_container;
  background-color: #EB575766;
  color: #EB5757;
}

.risk_container_none {
  @include risk_container;
  background-color: #17B67C66;
  color: #17B67C;
}