$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.wrapper {
  margin-top: 24px;
  border: 2px solid #17B67C;
  border-radius: 15px;
  padding: 20px;
  label {
    margin-bottom: 0 !important;
  }
  a {
    color: #17B67C;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-decoration-line: underline;
  }
  .checkBlock {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .comment {
    padding-top: 24px;
    a {
      text-decoration-line: none;
    }
  }
  .fields {
    width: 296px;
    height: 40px;
    input {
      border-radius: 4px;
      border: 1px solid $light-grey-200;
      background: $white-100;
    }
    margin-right: 8px;
  }
  .error {
    font-weight: 700 !important;
    color: #E65332 !important;
    margin-top: 8px;
    margin-bottom: 0;
  }
  .icon {
    width: 16px;
    height: 16px;

    &Success {
      @extend .icon;
      fill: #17b67c;
    }

    &Clock {
      @extend .icon;
      fill: #aab8d1;
    }
  }
  .iban {
    display: flex;
    align-items: center;
  }
  .ibanBlock {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
  .nameOfBank {
    margin-left: 8px;
  }
  .labelLabel {
    font-weight: 700 !important;
  }
  .phone__field {
    border: 1px solid #d3dff0;
    padding: 6px 20px;
    border-radius: 4px;
  }
  .phone__field::before {
    display: none;
  }
  .phone__field::after {
    display: none;
  }
  .mask {
    position: relative;
    width: 300px;
    max-width: 300px;
    margin-top: 48px;
    margin-right: 10px;
    label {
      position: absolute;
      top: -25px;
    }
    div {
      width: 100%;
      padding: unset;
      input {
        padding: 10px 20px;
        &::placeholder {
          color: #7786bb;
        }
        &:disabled {
          background: $white-100;
        }
      }
    }
  }
}