$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.tableRow {
	@media screen and (max-width: 768px) {
		overflow: auto;
		width: calc(100vw - 40px);
	}
}

.table {
	width: 100%;
	border-radius: 8px 8px 0 0;
	margin-top: 24px;

	@media screen and (max-width: 768px) {
		width: max-content;
	}

	thead {
		background: #5b6880;
		color: white;
		font-family: Inter, sans-serif;
		font-style: normal;
		font-weight: bold;

		th {
			text-align: left;
			padding: 11px 8px;
			font-size: 12px;
			line-height: 13.2px;
			word-break: break-word;

			&:first-child {
				border-top-left-radius: 8px;
			}

			&:last-child {
				border-top-right-radius: 8px;
			}
			&:hover {
				cursor: pointer;
			}
		}
	}

	tbody {
		tr {
			height: 40px;
			background: #f9fbff;
			border: 1px solid #d3dff0;

			td {
				font-family: Inter, sans-serif;
				padding: 11px;
				font-style: normal;
				font-weight: normal;
				font-size: 13px;
				line-height: 16px;
				color: #1d293f;
				width: max-content;
				word-break: break-word;

				&:hover {
					cursor: pointer;
				}
				text-align: start;
			}

			&:nth-child(even) {
				background: #f9fbff;
			}

			&:nth-child(odd) {
				background: white;
			}
		}
	}
}
.arrow {
    margin: 4px;
}

.tableLoading {
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 130%;
	/* or 31px */
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;

    /* Secondary Text */

    color: #5b6880;
}

.loaderAnimation {
    animation: spin 2s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.icon {
	width: 16px;
	height: 16px;

	&Success {
		@extend .icon;
		fill: #17b67c;
	}

	&Clock {
		@extend .icon;
		fill: #aab8d1;
	}
}

.text {
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	&Success {
		@extend .text;
		color: #17b67c;
	}

	&Clock {
		@extend .text;
		color: #aab8d1;
	}
}

.status {
	text-transform: lowercase;
}

.contractState {
	display: flex;
	align-items: center;
	justify-content: space-around;
	gap: 8px;
}

.messageCol {
	height: 200px;
	text-align: center;
}

.notWrappedCell {
	white-space: nowrap;
	overflow: hidden;
	max-width: 86px;

	&Agent {
		@extend .notWrappedCell;
		overflow: hidden;
		max-width: 116px;
	}

	&Type {
		@extend .notWrappedCell;
		max-width: 60px;
	}
}

// .pixelGaming {
// 	overflow: auto;
// 	width: 270px;

// 	&Checkbox {
// 		@extend .pixelGaming;
// 		max-width: 36px;
// 	}

// 	&ContractNumber {
// 		@extend .pixelGaming;
// 		max-width: 86px;
// 	}

// 	&Contragent {
// 		@extend .pixelGaming;
// 		max-width: 270px;
// 	}

// 	&Post {
// 		max-width: 270px;
// 	}

// 	&Date {
// 		@extend .pixelGaming;
// 		max-width: 130px;
// 		text-align: center;
// 	}

// 	&Status {
// 		@extend .pixelGaming;
// 		max-width: 130px;
// 	}

// 	&State {
// 		@extend .pixelGaming;
// 		max-width: 110px;
// 	}

// 	&Type {
// 		@extend .pixelGaming;
// 		max-width: 100px;
// 	}

// 	&Action {
// 		@extend .pixelGaming;
// 		max-width: 160px;
// 	}
// }

.signature__icon__wrapper {
	width: 16px;
	height: 16px;
	svg {
		width: 16px;
		height: 16px;
		path {
			width: 16px;
			height: 16px;
		}
	}
}

.tooltip {
	&__popper {
		background-color: #FFFFFF !important;
		box-shadow: 4px 4px 20px 2px rgba(42, 58, 86, 0.15) !important;
	}
	&__root {
		background-color: #FFFFFF !important;
		color: #5B6880 !important;
		margin: 0 !important;
		padding: 12px 23px !important;
		font-weight: 400 !important;
		font-size: 12px !important;
		line-height: 110% !important;
	}
}

.status__label {
	color: #AAB8D1;
}
.status__label__error {
	color: #EB5757;
}
.status__label__success {
	color: #17B67C;
}
.status__icon {
	fill: #AAB8D1;
	margin-right: 10px;
	width: 17px;
	height: 16px;
}
.status__icon__error {
	width: 17px;
	height: 16px;
	fill: #EB5757;
	margin-right: 10px;
}
.status__icon__success {
	fill: #17B67C;
	margin-right: 10px;
}