.modal {
	padding: 32px;
	max-width: 1216px;
	width: 90vw;
}

.socialLeaveModal {
	max-width: 1020px;
	width: 90vw;
	padding: 32px;
}

.title {
	font-weight: bold;
	font-size: 24px;
	line-height: 130%;
	color: $darkBlue
}

.wrapper {
	padding-right: 16px;
	overflow-y: auto;
	max-height: 80vh;
}

.subTitle {
	font-size: 16px;
	color: $grey-100;
	font-weight: 400;
}

.button {
	padding-top: 6px;
	padding-bottom: 6px;
}

.header {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 32px;

	&Right {
		display: flex;
		gap: 8px;
		justify-content: flex-end;
	}
}

.error {
	color: #E65332;
}


.footer {
	display: flex;
	justify-content: flex-end;
	margin: 20px 0;
}

.actions {
	display: flex;
	gap: 16px;
	justify-content: flex-start;
	margin-top: 40px;
	margin-bottom: 40px;
}

.icon {
	width: 14px;
	height: 14px;
	margin-right: 4px;

	&Green {
		@extend .icon;
		fill: $green;
	}

	&Grey {
		@extend .icon;
		fill: $grey-100;
	}

	&White {
		@extend .icon;
		fill: $white;
	}
}

.closeButton {
	display: flex;
	margin-left: auto;
	margin-top: 75px;
}

.tableTitleCell {
	width: 250px;
	font-weight: $bold-weight;
	color: $darkBlue;
	margin-bottom: 4px;
}

.content {
	text-align: center;
	max-width: 452px;

	&__title {
		color: $darkBlue;
	}

	&__body {
		color: $grey-100;
		font-size: 16px;

		&_dark {
			font-weight: 700;
			color: $darkBlue;
		}
	}
}

.mt44 {
	margin-top: 44px;
}

.identifier {
	color: $grey-400;
	margin: 8px 0;
}

// ЭТД
.etd__titile {
	font-weight: 400;
	font-size: 24px;
	line-height: 130%;
	color: #5B6880;
	margin-bottom: 32px;
}
.etd__wrapper {
	margin: 40px 0;
}
.clause {
	margin-bottom: 10px;
	&__title {
		font-weight: 700;
		font-size: 14px;
		line-height: 110%;
		color: #1D293F;
	}
	&__text {
		font-weight: 400;
		font-size: 14px;
		line-height: 120%;
		color: #5B6880;
		width: 50%;
	}
}

.etd__tip {
	margin: 40px 0;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
	color: #5B6880;
	width: 70%;
}

.etd__table__title {
	font-weight: 400;
	font-size: 24px;
	line-height: 130%;
	color: #5B6880;
}

.table__container {
	border-radius: 8px 8px 0px 0px;
	border: 1px solid $light-grey-200;
  overflow: hidden;
  overflow-x: unset !important;
}

.table__head__row {
	background-color: #5B6880;
	color: white !important;
	span {
		color: white !important;
	}
	svg {
		color: white !important;
	}
}

.table__row:nth-child(2n) {
	background-color: #EFF3FC;
}

@media (max-width: 1000px) {
	.tableTitleCell {
		width: 100%;
	}
}

.etd__title {
	font-weight: 400;
	font-size: 24px;
	line-height: 130%;
	color: #5B6880;
}
.employee__comment {
	border: 1px solid #AAB8D1;
	border-radius: 14px;
	margin-top: 15px;
	padding: 16px;
	font-weight: 400;
	font-size: 16px;
	line-height: 110%;
	color: #1D293F;
}
.modalWS {
	padding: 32px;
	max-width: 1216px;
	width: 90vw;
	display: block;
	margin-bottom: 50px;
	margin-top: 50px;
	margin: 100px auto;
}
.employee__comment__wrapper {
	margin: 31px 0px;
}
.button_etd {
	font-family: 'Inter', sans-serif !important;
	font-weight: 400 !important;
	font-size: 16px !important;
	line-height: 130% !important;
	color: #5B6880 !important;
	padding: 0 !important;
	text-transform: none !important;
}
.reset__button {
	color: #9aa0ac !important;
}
.button__disabled {
	opacity: 0.5 !important;
}
.edit__icon {
	width: 22px;
	height: 22px;
}
.button__save {
	margin-right: 30px !important;
	margin-left: 30px !important;
	padding: 5px 15px !important;
	border: 1px solid #17B67C !important;
	font-family: 'Inter', sans-serif !important;
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 130% !important;
	color: #17B67C !important;
}
.buttons__wrapper {
	display: flex;
	justify-content: flex-end;
	padding: 32px 0 30px;
}
.registrationDate {
	color: $grey-100;
	margin-bottom: 34px;
}
.loader {
	display: flex;
	align-items: center;
}
.decline__button {
	display: flex;
	justify-content: flex-end;
}
.send__worker {
	margin-left: 14px;
}

.contentAdditional {
	text-align: center;
	max-width: 452px;

	p {
		text-align: center;
		font-family: 'Inter', sans-serif;
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 130%;
	}
}

.sign {
	min-width: 500px;
	max-width: 500px !important;
	padding-right: 0;
	padding-left: 0;
}

.sign__title {
	 font-weight: 700 !important;
	 font-size: 24px !important;
	 line-height: 130% !important;
	 text-align: center !important;
	 color: #1D293F !important;
	 padding-top: 65px !important;
 }
.dialog__buttons__wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.flex_button {
	display: flex;
}
.margin_button {
	 margin: 20px;
 }
.error__qr {
	text-align: center;
	color: #E65332;
	margin-bottom: 20px;
}
.button {
	padding-top: 6px;
	padding-bottom: 6px;

	&Table {
		@extend .button;
		margin-top: 32px;
	}
}
.reset__button {
	font-size: 14px !important;
	color: #9aa0ac !important;
}