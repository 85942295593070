@import 'src/features/contracts/additionalContracts/AdditionalContractStyles.module';

.header {
  display: flex;

  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    color: $darkBlue
  }
}

.closeButton {
  display: flex;
  margin: 2rem 0 0 auto;
  padding-left: 3rem;
  padding-right: 3rem;
}
