.radio {
	position: absolute;
	z-index: -1;
	opacity: 0;
	margin: 10px 0 0 7px;
}

.radio + label {
	position: relative;
	padding: 0 0 0 35px;
	cursor: pointer;
}

.radio + label:before {
	content: '';
	position: absolute;
	left: 3px;
	width: 14px;
	height: 14px;
	border: 1px solid #aab8d1;
	border-radius: 50%;
	background: #fff;
}

.radio + label:after {
	content: '';
	position: absolute;
	top: 0px;
	left: 3px;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background-image: url(../../assets/entrance/componentButton.svg);
	opacity: 0;
	transition: 0.2s;
}

.radio:checked + label:after {
	opacity: 1;
}
