$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.popup {
  visibility: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: absolute;
  width: 274px;
  height: auto;
  transform: translateX(calc(-100% + 24px));
  background-color: $white;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s linear;
  z-index: 150;
}

.show {
  visibility: visible;
  opacity: 1;
}

.container {
  display: flex;
  flex-direction: column;
}

.tabs {
  border-bottom: 1px solid $light-grey-200;
  display: flex;
  text-align: center;
}

.tab {
  padding: 12px 0 15px 0;
  text-transform: uppercase;
  color: $whiteBlue;
  flex: 1;
  cursor: pointer;
  &:hover {
    color: $green;
  }
}

.tab.active {
    color: $darkBlue;
    border-bottom: 1px solid $darkBlue;
}

.mainContent {  
  padding: 14px;  
}

.mainContent__plug {  
  min-height: 73px;
}

.scrollArea {
  max-height: 211px;
  overflow-y: scroll;
}

.profile {
  display: grid;
  grid-template-columns: 60px 1fr;
  gap: 8px;
}

.selectProfile {
  display: grid;
  grid-template-columns: 60px 1fr;
  gap: 8px;
  margin-bottom: 14px;
  cursor: pointer;
  &:only-of-type {
    margin-bottom: 0;
  }
  &:hover {
    color: $green;
  }
}

.footer {
  border-top: 1px solid $light-grey-200;
  padding: 14px;
}

.email {
  border-top: 1px solid $light-grey-200;
  color: $light-grey-200;
  display: flex;
  p {
    margin: 10px;
  }
}

.companyName {
  font-size: 12px;
  margin: 0;
}

.logo {
  max-width: 60px;
  max-height: 60px;
  border-radius: 8px;
  object-fit: cover;
}

.changeCompanyButton {
  padding: 0;
  height: fit-content;
  font-size: 14px;
}

.link {
  font-weight: $regular-weight;
  color: $green;
  display: block;
  cursor: pointer;
}

.nameValue {
  margin-bottom: 0;
  font-size: 12px;
  line-height: 14.4px;
  font-weight: $regular-weight;
}

@media (max-width: 1000px) {
  .popup {
    width: calc(100% - 40px);
  }
}