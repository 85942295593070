$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.wrapper {
	width: 1100px;
	background: $white;
	height: 80vh;
	padding: 0 30px;
	overflow-y: scroll;
}

.form {
	display: grid;
	grid-row-gap: 56px;
	margin-top: 64px;
	padding: 0 30px;
	width: 100%;
}

.item {
	display: grid;
	grid-row-gap: 24px;
}

.fields {
	display: grid;
	grid-column-gap: 32px;
	grid-template-columns: 1fr 2fr;
}

.fieldsAndButton {
	.fieldAndButton {
		display: grid;
		grid-template-columns: max-content 1fr;
		grid-column-gap: 32px;
		align-items: flex-end;
	}
}

.threeFields {
	grid-template-columns: 32.5% 30.7% 30.6%;
}

.actions {
	display: flex;
	justify-content: flex-end;
	grid-column-gap: 60px;
}

.required__field {
	font-weight: $bold-weight;
}
