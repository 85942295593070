$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.wrapper {
  width: 1100px;
  background: $white;
  height: 80vh;
  padding: 0 30px;
  overflow-y: auto;
  margin-top: 20px;
}
.mainInformation {
  display: flex;
  justify-content: space-between;
}

.identifier {
  color: $grey-400;
  margin: 8px 0;
}

.registrationDate {
  color: $grey-100;
  margin-bottom: 34px;
}

.actions {
  display: flex;
  gap: 16px;
}

.informationSections {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 25px;
}

.container {
  display: flex;
  gap: 40px;
}

.subTitle {
  margin-bottom: 8px;
  margin-top: 0;
  color: $grey-400;
  font-size: 18px;
  font-weight: $middle-weight;
  // margin-top: 24px;
}

.titles {
  margin-right: 20px;
  font-weight: $bold-weight;
  color: $darkBlue;
  width: 110px;
}

.titles40p {
  margin-right: 20px;
  font-weight: $bold-weight;
  color: $darkBlue;
  width: 40%;
}
.values {
  color: #5b6880
}

.tableContainer {
  display: flex;
  flex-direction: column;
}

.table {
  display: flex;
  flex-direction: column;
  .row {
    margin-bottom: 7px;
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.cell {
  flex: 1;
}

.cell6 {
  flex: 6;
}

.boldCell {
  flex: 1;
  font-weight: $bold-weight;
}

.buttonEnd {
  margin: 20px 0;
  float: right;
}

.badge{
  color: white;
  text-transform: lowercase;
  background: #AAB8D1;
  border-radius: 4px;
  padding: 4px 8px;
  width: max-content;
  float: left;
  margin: 10px 10px 10px 0;
}

.tariffTable{
  margin: 24px 0;
  width: 1200px;
  border-radius: 8px 8px 0 0;

  @media screen and (max-width: 768px) {
    width: max-content;
  }
  thead {
    font-style: normal;
    font-weight: bold;

    th {
      text-align: left;
      font-size: 14px;
      line-height: 16px;
      word-break: break-word;

      border-bottom: 1px solid #d3dff0;
    }
  }

  tbody {
    tr {
      &:hover {
        cursor: auto;
      }

      height: 40px;
      border-bottom: 1px solid #d3dff0;

      td {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #1d293f;
        width: max-content;
        word-break: break-word;

        &:hover {
          cursor: pointer;
        }
        text-align: start;
      }
    }
  }
}

.tariffTableWrapper{
  width: 100%;
  overflow-x: auto;
}