$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.wrapper {
  margin-top: 44px;
  margin-bottom: 44px;
}

.file {
  list-style: none;
  li {
    font-size: 16px;
    line-height: 110%;
    color: #5b6880;
    padding: 11px 16px 11px 0;
    display: flex;
    img {
      margin-right: 10px;
      margin-bottom: -2px;
    }
    span {
      font-size: 16px;
      line-height: 110%;
    }
  }
  &__delete {
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    color: #eb5757;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 15px;
    img {
      margin-right: 8px;
    }
  }
}

.size {
  font-size: 16px;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: 0;
  text-align: left;
  color: #AAB8D1;
  margin-top: 5px;
  margin-bottom: 5px;
}

.hintIcon {
  fill: #17b67c;
  margin-left: 8px;
}

.error {
  font-weight: 700 !important;
  color: #E65332 !important;
}

.formBtn {
  display: flex;
  flex-direction: row;
  & > * {
    margin-right: 15px;
  }
}