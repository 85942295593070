@import "../../../PersonalAffairsMainTab/components/Table/Table.module";

.textStatus {
  margin-left: 8px;
}

.textStatusWrapper {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  text-align: left;
}

.thText {
  text-align: left;
  line-height: 16px !important;
}
