.wrapper-notify .ant-popover-inner {
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
    width:507px;
}

.ant-badge-status-success {
    background-color: #17B67C !important;
}

span#badge_header_icon.ant-badge-status {
    cursor: pointer !important;
}
.wrapper-notify .ant-popover-inner-content {
    width: auto !important;
}
.ant-popover.wrapper-notify {
    left: unset !important;
    right: 60px;
}