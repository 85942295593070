$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.accordionItem {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  position: relative;
  overflow-anchor: none;
  margin-bottom: 16px;
  box-shadow: none;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 0px;

  &__isExpanded {
    margin: 16px 0;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    border-left: 1px solid rgb(170, 184, 209);
    border-right: 1px solid rgb(170, 184, 209);
    border-bottom: 1px solid rgb(170, 184, 209);
    border-radius: 10px;
  }
}

.accordionHeader {
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  color: inherit;
  display: flex;
  min-height: 48px;
  outline: 0px;
  margin: 0px;
  text-decoration: none;
  padding: 0px 16px;
  transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  border-width: 2px;
  border-style: solid;
  border-color: rgb(170, 184, 209);
  border-image: initial;
  border-radius: 10px;


  &__isExpanded {
    border-color: rgb(23, 182, 124);
    min-height: 64px;
  }
}

.accordionHeaderInner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 12px 0;
  -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &__isExpanded {
    margin: 20px 0;
  }
}

.accordionIcon {
  -webkit-transition: -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &__isExpanded {
    transform: rotate(180deg);

    fill: $green !important;
  }
}

.accordionContent {
  min-height: 0px;
  height: 0px;
  transition-duration: 293ms;
  overflow: hidden;
  -webkit-transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &__isExpanded {
    height: auto;
    overflow: visible;
    -webkit-transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}

.accordionContentInner {
  padding: 8px 16px 16px;
  box-shadow: none;

  &__isExpanded {

  }
}