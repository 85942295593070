$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.tariff_content{

  &__subtitle{
    color: $grey-100;

    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  &__wrapper{
    display: grid;
    grid-column-gap: 32px;

    &twoOne{
      grid-template-columns: 2fr 1fr;
    }

    &three{
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

}

.fields__wrapper {
  display: flex;
  justify-content: space-between;
}

.fields {
  display: grid;
  grid-column-gap: 32px;
  grid-template-columns: 1fr 2fr;
  align-items: flex-start;

  &_verticalCenter {
    align-items: center;
  }
}

.button {
  width: 240px;
  align-items: center;
  padding-left: 0;
  margin-top: 20px;
  font-weight: 800;
  &Red {
    @extend .button;
    color: #EB5757;
    width: 100px;
  }
}

.field__input {
  width: 100%;
  margin-right: 16px;
}

.icon {

  width: 12px;
  height: 12px;
  margin-right: 8px;
  &Green {
    @extend .icon;
    fill: $green;
  }

  &Red {
    @extend .icon;
    fill: #EB5757;
  }
}

.errorText {
  color: #eb5757;
}