* {
	margin: 0;
	padding: 0;
}

.btn {
	width: 271px;
	border-radius: 2px;
	border-style: none;
	padding: 10px 22px;
	background-color: rgba(23, 182, 124, 1);
	cursor: pointer;
	font-family: 'Inter', sans-serif;
	color: rgba(255, 255, 255, 1);
	font-weight: 500;
	font-size: 16px;
}

.btn:disabled {
	opacity: 0.5;
}
.btn:disabled:hover {
	color: rgba(255, 255, 255, 1);
}

.btn:hover {
	background-color: rgba(29, 41, 63, 1);
	transition: 0.2s;
}

@keyframes fadeIn {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
