$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.table {
  width: 100%;
  border-radius: 8px 8px 0 0;
  margin-top: 24px;

  @media screen and (max-width: 768px) {
    width: max-content;
  }
  thead {
    display:table;
    width:100%;
    background: #5b6880;
    color: white;
    font-style: normal;
    font-weight: bold;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-align: left;
    word-break: break-word;

    th {
      text-align: left;
      padding: 11px 11px;
      font-size: 12px;
      line-height: 16px;
      word-break: break-word;
    }
  }

  tbody {
    display:block;
    height:600px;
    overflow:auto;
    tr {
      display:table;
      width:100%;
      table-layout:fixed;

      &:nth-child(even) {
        background: #EFF3FC;
      }

      &:hover {
        cursor: auto;
      }

      height: 40px;
      background: #f9fbff;
      border: 1px solid #d3dff0;

      td {
        padding: 11px;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #1d293f;
        width: max-content;
        word-break: break-word;

        &:hover {
          cursor: pointer;
        }
        text-align: start;
      }

      &:nth-child(even) {
        background: #f9fbff;
      }

      &:nth-child(odd) {
        background: white;
      }
    }
  }
}