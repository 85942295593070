$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.modal_wrapper {
  width: 90%;
  padding: 30px;

  &__send{
    max-width: 1000px;
  }

  &__phone{
    max-width: 620px;
  }

  &__sms{
    max-width: 500px;
  }

  .wrapper {
    padding-right: 16px;
    overflow-y: auto;
    max-height: 80vh;
  }

  .header {
    display: flex;
    gap: 32px;
    align-items: center;
  }

  .title {
    color: $darkBlue;
    margin-bottom: 0;

    &big{
      @extend .title;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }

    &small{
      @extend .title;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }
  }

  .subtitle {
    color: $whiteBlue;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  .body {
    .med_title {
      color: $darkBlue;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
    }
  }

  .timeline {

    &__date {
      color: $grey-100;
    }

    &__status {
      color: #1D293F;
      font-weight: 600;
    }
  }
}


.timelineDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.info {

  display: flex;
  flex-direction: column;
  gap: 6px;

  .info__item {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .info__title {
    color: $darkBlue;
    font-weight: 600;
  }

  .info__subtitle {
    color: $grey-100;
  }
}

.footer{
  display: flex;
  gap: 32px;
  justify-content: end;
}

.tel {
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--text-primary, $black);
  font-family: Montserrat Alternates, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}

.sms_text {
  color: var(--Secondary-Text, $grey-100);
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}

.button_send {
  margin-top: 20px;
}

.error {
  color: $red;
}