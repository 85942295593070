$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.table {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 16px;
}

.modal_container {
  display: flex;
  flex-direction: column;
  padding: 32px 32px 32px 32px;
  margin-top: 20px;
  max-width: 1400px;
  width: 100%;
  max-height: 100dvh;
  gap: 32px;
  overflow-y: scroll;

  @media (max-width: 1000px) {
    .modal_container {
      padding: 15px 20px 0 20px;
    }
  }
}

.modal_title_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.modal_common_information {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.modal_indicator_information {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal_risk_information {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal_risk_table_container {
  max-width: 1400px;
  box-sizing: border-box;
}

.modal_risk_table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 49px;
}

.risk_table_column {
  display: flex;
  flex-direction: column;
}

.risk_table_cell {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
}

.risk_table_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.risk_table_body {
    display: flex;
    flex-direction: column;
    gap: 21px;
}

.company_portriet_container {
  display: flex;
  flex-direction: Column;
  gap: 20px;
  max-width: 850px;
}

.company_portriet_table {
  display: grid;
  grid-template-columns: 274px 1fr;
  column-gap: 60px;
  row-gap: 20px;
}

.company_portriet_column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.company_portriet_table_cell {
    display: flex;
    flex-direction: column;
    gap: 8px
}

.company_portriet_table_sub_cell {
  display: flex;
  flex-direction: column;
  gap: 4px
}

.company_portriet_table_sub_cell_row {
    display: flex;
    flex-direction: row;
    gap: 4px;
    justify-content: space-between;
}

.violation_container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.violation_table {
    display: grid;
    grid-template-columns: 1fr 1fr 200px;
    column-gap: 30px;
    hr {
      margin: 0;
    }
}

.violation_table_delimiter {
    grid-column: span 3;
}

.worker_profile_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.worker_profile_table {
  display: grid;
  grid-template-columns: 314px 1fr;
  column-gap: 60px;
}

.worker_profile_prosperity_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.worker_profile_prosperity_table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 4px;
}


