.tableRow {
	@media screen and (max-width: 768px) {
		overflow: auto;
		width: calc(100vw - 40px);
	}
}

.table {
	width: 100%;
	border-radius: 8px 8px 0 0;
	margin-top: 24px;

	@media screen and (max-width: 768px) {
		width: max-content;
	}

	thead {
		background: #5b6880;
		color: white;
		font-family: Inter, sans-serif;
		font-style: normal;
		font-weight: bold;

		th {
			text-align: left;
			padding: 11px 11px;
			font-size: 12px;
			line-height: 13.2px;
			word-break: break-word;

			&:first-child {
				border-top-left-radius: 8px;
			}

			&:last-child {
				border-top-right-radius: 8px;
			}
			&:hover {
				cursor: pointer;
			}
		}
	}

	tbody {
		tr {
			height: 40px;
			background: #f9fbff;
			border: 1px solid #d3dff0;

			td {
				font-family: Inter, sans-serif;
				padding: 11px;
				font-style: normal;
				font-weight: normal;
				font-size: 13px;
				line-height: 16px;
				color: #1d293f;
				width: max-content;
				word-break: break-word;

				&:hover {
					cursor: pointer;
				}
				text-align: start;
			}

			&:nth-child(even) {
				background: #f9fbff;
			}

			&:nth-child(odd) {
				background: white;
			}
		}
	}
}
.arrow {
    margin: 4px;
}

.tableLoading {
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 130%;
	/* or 31px */
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;

    /* Secondary Text */

    color: #5b6880;
}

.loaderAnimation {
    animation: spin 2s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


.status {
	text-transform: lowercase;
}

.messageCol {
	height: 200px;
	text-align: center;
	p {
		font-weight: $bold-weight;
		font-size: 24px;
		margin-bottom: 20px;
	}
}

.notWrappedCell {
	white-space: nowrap;
	overflow: hidden;
	max-width: 86px;

	&Agent {
		@extend .notWrappedCell;
		overflow: hidden;
		max-width: 116px;
	}

	&Type {
		@extend .notWrappedCell;
		max-width: 60px;
	}
}

.pixelGaming {
	overflow: auto;
	width: 270px;

	&Checkbox {
		@extend .pixelGaming;
		max-width: 36px;
	}

	&ContractNumber {
		@extend .pixelGaming;
		max-width: 86px;
	}

	&Contragent {
		@extend .pixelGaming;
		max-width: 270px;
	}

	&Post {
		max-width: 270px;
	}

	&Date {
		@extend .pixelGaming;
		max-width: 130px;
		text-align: center;
	}

	&Status {
		@extend .pixelGaming;
		max-width: 130px;
	}

	&State {
		@extend .pixelGaming;
		max-width: 110px;
	}

	&Type {
		@extend .pixelGaming;
		max-width: 100px;
	}

	&Action {
		@extend .pixelGaming;
		max-width: 160px;
	}
}

.signature__icon__wrapper {
	width: 16px;
	height: 16px;
	svg {
		width: 16px;
		height: 16px;
		path {
			width: 16px;
			height: 16px;
		}
	}
}

.tooltip {
	&__popper {
		background-color: #FFFFFF !important;
		box-shadow: 4px 4px 20px 2px rgba(42, 58, 86, 0.15) !important;
	}
	&__root {
		background-color: #FFFFFF !important;
		color: #5B6880 !important;
		margin: 0 !important;
		padding: 12px 23px !important;
		font-weight: 400 !important;
		font-size: 12px !important;
		line-height: 110% !important;
	}
}
.minWidth {
	min-width: max-content;
}