.btn {
	border-radius: 2px;
	border-style: none;
	padding: 10px 22px;
	background-color: rgba(23, 182, 124, 1);
	cursor: pointer;
	font-family: 'Inter';
	color: rgba(255, 255, 255, 1);
	font-weight: 500;
	font-size: 16px;
	display: flex;
	align-items: center;
}
.btnDis {
	border-radius: 2px;
	border-style: none;
	padding: 10px 22px;
	background-color: rgba(23, 182, 124, 0.5);
	cursor: pointer;
	font-family: 'Inter', sans-serif;
	color: rgba(255, 255, 255, 1);
	font-weight: 500;
	font-size: 16px;
	display: flex;
	align-items: center;
}

.btnNotActive {
	height: 40px;
	border-radius: 2px;
	border-style: none;
	padding: 5px 16px 5px 16px;
	background-color: rgb(255, 255, 255);
	cursor: pointer;
	font-family: 'Inter', sans-serif;
	color: rgba(255, 255, 255, 1);
	font-weight: 500;
	font-size: 16px;
}

.btn img {
	width: 16px;
	height: 16px;
	margin-right: 8px;
}

.btn:hover {
	background-color: rgba(29, 41, 63, 1);
	transition: 0.2s;
	animation: fadeIn ease-out 0.6s;
	cursor: pointer;
}

.btnDis:hover {
	cursor: unset;
}

@media (max-width: 720px) {
	.btn {
		/* width: 224px; */
		margin-bottom: 16px;
	}
}

@media (max-width: 320px) {
	.btn {
		/* width: 224px; */
		margin-bottom: 16px;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
