span#n_text {
    font-size: 14px;
    font-weight: bold;
    color: #1D293F;
}

button#open.ant-btn-sm > span {
    display: inline-block;
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    margin-right: 0;
    text-decoration: underline;
    color: #17b67c;
    padding: 0px 0px;
}

button#expand.ant-btn > span {
    display: inline-block;
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    margin-right: 0;
    color: #17b67c;
    background-color: white;
}
button#expand.ant-btn {
    background-color: white;
}

button#open.ant-btn-sm {
    height: 24px;
    padding: 0px 0px;
    font-size: 14px;
    border-radius: 2px;
}
button#open.ant-btn {
    background-color: white;
}

.ant-divider-horizontal {
    display: flex;
    clear: both;
    width: 100%;
    min-width: 100%;
    margin: 0 0;
}

.ant-divider-horizontal {
    display: flex;
    clear: both;
    width: 100%;
    min-width: 100%;
    margin: 0 0 !important;
}

li.ant-list-item {
    cursor: pointer !important;
}
