/*@font-face {
	font-family: 'PT Root UI';
	src: local('PT Root UI Regular'), local('PT-Root-UI-Regular'), url('PTRootUI-Regular.woff2') format('woff2'), url('PTRootUI-Regular.woff') format('woff'),
		url('PTRootUI-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}*/
@font-face {
    font-family: 'Inter';
    src: url('Inter-Regular.eot');
    src: local('Inter Regular'), local('Inter-Regular'),
    url('Inter-Regular.eot?#iefix') format('embedded-opentype'),
    url('Inter-Regular.woff2') format('woff2'),
    url('Inter-Regular.woff') format('woff'),
    url('Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Inter Medium';
    src: url('Inter-Medium.eot');
    src: local('Inter Medium'), local('Inter-Medium'),
    url('Inter-Medium.eot?#iefix') format('embedded-opentype'),
    url('Inter-Medium.woff2') format('woff2'),
    url('Inter-Medium.woff') format('woff'),
    url('Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Inter Bold';
    src: url('Inter-Bold.eot');
    src: local('Inter Bold'), local('Inter-Bold'),
    url('Inter-Bold.eot?#iefix') format('embedded-opentype'),
    url('Inter-Bold.woff2') format('woff2'),
    url('Inter-Bold.woff') format('woff'),
    url('Inter-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat Alternates';
    src: url('MontserratAlternates-Bold.eot');
    src: local('Montserrat Alternates Bold'), local('MontserratAlternates-Bold'),
    url('MontserratAlternates-Bold.eot?#iefix') format('embedded-opentype'),
    url('MontserratAlternates-Bold.woff2') format('woff2'),
    url('MontserratAlternates-Bold.woff') format('woff'),
    url('MontserratAlternates-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
