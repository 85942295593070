.button {
    border: 1px solid #D3DFF0;
    color: #3F5276;
    padding: 0px 10px;
    border-radius: 19px;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    height: 23px;
}

.button.active {
    background: #D3DFF0;
}