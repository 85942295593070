.hr-pagination-wrapper {
    display: flex;
    /* justify-content: space-between; */
    /* width: 100%; */
    /* max-width: 100%; */
    /* margin-right: 31px; */
    box-sizing: content-box;
    margin-bottom: 182px;
    margin-top: 36px;
    flex-wrap: wrap;
    align-items: center;
}

.margin-bottom-zero { margin-bottom: 0 }

.hr-pagination-right {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    max-width: 360px;
    height: 32px;
}

.content-show-per-page {
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    color: #1d293f;
    display: flex;
    justify-content: center;
    height: 32px;
    flex-direction: column;
    margin: 0 10px 0 0;
}

.content-button-per-page {
    cursor: pointer;
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    color: #aab8d1;
    display: flex;
    justify-content: center;
    height: 32px;
    width: 40px;
    min-width: 40px;
    flex-direction: column;
    text-align: center;
}

.content-button-per-page-count {
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    color: #aab8d1;
    display: flex;
    justify-content: center;
    height: 32px;
    min-width: 20px;
    flex-direction: column;
    text-align: center;
}

.content-button-per-page-active {
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    background-color: #17b67c;
    color: white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    height: 32px;
    width: 40px;
    flex-direction: column;
    text-align: center;
}

.hr-pagination-left {
    display: flex;
    justify-content: space-around;
    align-content: center;
    margin-right: auto;
    margin-left: 0;
    max-width: 500px;
    height: 32px;
}

.page {
    display: flex;
    justify-content: center;
    width: 32px;
    color: #aab8d1;
    background-color: white;
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
}

.navigation {
    font-size: 12px;
    font-weight: bold;
    color: #17b67c;
    display: flex;
    margin: 0 11px;
}

.item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 32px;
    flex-direction: column;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@media screen and (max-width: 768px) {
    .hr-pagination-right {
        display: flex;
        justify-content: center;
        margin: 24px -10px 0 -10px;
    }
}
