$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.section_wrapper {
  display: flex;

  .section_block {
    width: 70%;
  }

  .section_empty {
    width: 100%;
    p {
      font-size: 18px;
    }

  }

  .empty_topic {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    background: $light-grey;
    border: 1px solid #D2D4D9;
    box-shadow: 4px 4px 20px 4px rgba(91, 104, 128, 0.1);
    border-radius: 25px;
    z-index: 1;

    .theme_empty {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 0;
    }

    .empty_circle {
      height: 500px;
      position: relative;
      &_result {
        height: 1px;
      }
    }

    .empty_circle_left {
      position: relative;
      left: 0;
      z-index: 1;
    }

    .empty_circle_right {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;
      &_result {
        position: absolute;
        right: 0;
        top: 50px;
        z-index: 0;
      }
    }

    .mrg {
      position: relative;
      z-index: 999;
      margin: 24px;
    }

    .button_result {
      float: right;
      margin: 30px 0 40px;
    }
  }

  .empty_topic_title {
    position: absolute;
    width: 460px;
    height: 84px;
    left: 25%;
    top: 10%;
    align-items: center;
    text-align: center;
    z-index: 999;

    p {
      font-weight: 700;
      font-size: 32px;
      color: #5B687F;
      line-height: 130%;
      margin-bottom: 0;
    }
  }

  .empty_topic_text {
    position: absolute;
    width: 460px;
    height: 92px;
    left: 25%;
    top: 25%;
    align-items: center;
    text-align: center;
    z-index: 999;
    margin-top: 14px;

    p {
      font-weight: 400;
      font-size: 24px;
      color: #5B687F;
      line-height: 130%;
      margin-bottom: 0;
    }

  }

  .title_result {
    display: flex;
    justify-content: space-between;
  }

  .result {

  }

  .td_block {
    width: 15%;
  }

  .identCaptcha {
    margin-top: 15px;
  }

  .back {
    display: none;
  }

  @media (max-width: 768px) {
    .section_block_title {
      display: none;
    }
    .section_block {
      display: contents;
    }
    .result {
      display: none;
    }
    .section_empty {
      display: none;
    }
    .back {
      color: rgba(23, 182, 124, 1);
      font-size: 16px;
      cursor: pointer;
      display: inline-block;
    }
  }
}