@import "../../../../../../features/contracts/table/contractsTable.module";

.table {
  tbody {
    tr {
      &:nth-child(even) {
        background: #EFF3FC;
      }

      &:hover {
        cursor: auto;
      }

      td {

        &:hover {
          cursor: auto;

          &:last-child {
            cursor: pointer;
          }
        }
      }
    }
  }
}
