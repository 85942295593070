$green: #17b67c;
$black: #000000;
$black-05: rgba(0, 0, 0, 0.5);
$blackLight: rgba(255, 255, 255, 0);
$blue: #5792EB;
$light-blue: #C4D9F8;
$light-green: #ceefe3;
$darkBlue: #1d293f;
$darkGrey: #093344;
$darkGrey-100: rgba(29, 41, 63, 1);
$orange: #e65332;
$whiteBlue: #aab8d1;
$white: #ffffff;
$white-100: #f9fbff;
$white-200: #e9eef1;
$grey: #3f5276;
$grey-100: #5b6880;
$grey-200: #c6cdd6;
$grey-300: #e7eef8;
$grey-400: #9ca5b6;
$grey-500: #6b7280;
$light-grey: #f1f4f8;
$light-grey-100: #f1f6fc;
$red: #eb5757;
$red-500: #ff0000;
$light-grey-200: #d3dff0;
$yellow: #ffd500;
$orange-100: #F19204;
$orange-200: #eba834;
;

$regular-weight: 400;
$middle-weight: 500;
$bold-weight: 700;

.insuranceContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.insuranceHeader {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;

    @media (max-width: 980px) {
        grid-template-columns: 1fr 1fr;
    }
}

.indicatorContainer {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 12px 16px;
    border-radius: 2px;
    border: 1px solid #D3DFF0;
    gap: 16px;
}

.indicatorHeader {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.indicatorBody {
    display: flex;
    flex-direction: column;
}

.modalContainer {
    width: 90%;
    padding: 36px 32px 36px 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.modalTitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.tableInfo {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 16px;
}